<template>
  <div
    class="d-flex flex-wrap flex-md-nowrap pt-4 mx-auto"
    style="max-width: 1600px;"
  >
    <div
      v-if="dataCartAnonymous"
      id="checkout_2"
      class="col-12 col-md-7 col-xl-8 me-0"
      :class="stepPayment == 5 ? 'col-md-12 col-xl-12' : ''"
    >
      <div v-if="stepPayment <= 4" class="container-data-checkout">
        <div
          v-if="dataCartAnonymous.productos.find(p => p.status == 0)"
          class="container-step-checkout"
        >
          <div class="alert-product-spent">
            <p>
              Uno o más productos de tu bolsa están agotados, revisa tus
              productos antes de finalizar la compra.
            </p>
            <router-link :to="{ name: 'MyBag' }">Volver al carrito</router-link>
          </div>
        </div>
        <div class="container-step-checkout">
          <div class="head-step-checkout">
            <div class="title-head-step-checout">
              <h5>
                <span :class="stepPayment > 1 ? 'done bold' : ''">01</span>
                Correo electrónico
              </h5>
              <span
                v-if="stepPayment > 1"
                class="title-head-step-checkout-email order-3 order-md-2"
                >{{ dataCartAnonymous.carrito.cliente_correo }}</span
              >
              <span
                v-if="stepPayment > 1"
                style="color: rgb(101, 64, 98);"
                class="order-2 order-md-3"
                @click="stepPayment = 1"
                >Editar</span
              >
            </div>
          </div>
          <div v-if="stepPayment == 1" class="body-step-checkout">
            <email-checkout-anonymo
              @nextStep="stepPayment = 2"
              @maskData="shoeModalMaskData = true"
            />
          </div>
        </div>
        <div class="container-step-checkout">
          <div class="head-step-checkout">
            <div class="title-head-step-checout">
              <h5>
                <span :class="stepPayment > 2 ? 'done bold' : ''">02</span>
                Identificación
              </h5>
              <span
                v-if="stepPayment > 2 || (stepGeneral > 2 && stepPayment != 2)"
                style="color: rgb(101, 64, 98);"
                @click="
                  dataCartAnonymous.carrito.carrito_editar == 0
                    ? $bvModal.show('modal-login')
                    : (stepPayment = 2)
                "
                >Editar</span
              >
            </div>
            <div
              v-if="stepGeneral > 2 && stepPayment != 2"
              class="container-head-step-checout"
            >
              <p class="mb-0">
                {{ dataCartAnonymous.carrito.tipodoc }}:
                {{ dataCartAnonymous.carrito.cliente_documento }} <br />
                Nombre: {{ dataCartAnonymous.carrito.cliente_nombre }}
                {{ dataCartAnonymous.carrito.cliente_apellidos }} <br />
                Celular: {{ dataCartAnonymous.carrito.cliente_celular }}
              </p>
            </div>
          </div>
          <div v-if="stepPayment == 2" class="body-step-checkout">
            <information-checkout-anonymo class="mb-4" />
          </div>
        </div>
        <div class="container-step-checkout">
          <div class="head-step-checkout">
            <div class="title-head-step-checout">
              <h5>
                <span :class="stepPayment > 3 ? 'done bold' : ''">03</span>
                Envío
              </h5>
              <span
                v-if="stepPayment > 3 || (stepGeneral > 3 && stepPayment != 3)"
                style="color: rgb(101, 64, 98);"
                @click="
                  stepPayment = 3
                  entregarOtraDir = false
                "
                >Editar</span
              >
            </div>
            <div
              v-if="stepGeneral > 3 && stepPayment != 3"
              class="container-head-step-checout"
            >
              <p class="mb-3">
                {{ dataCartAnonymous.carrito.cliente_direccion }},
                {{ dataCartAnonymous.carrito.observaciones }} <br />
                {{
                  dataCartAnonymous.carrito.cliente_ciudad_texto
                    .split(', ')[0]
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                }},
                {{
                  dataCartAnonymous.carrito.cliente_ciudad_texto
                    .split(', ')[1]
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                }}
              </p>
              <p class="bold">Tiempo de entrega apróximado:</p>
              <div class="container-step-envio-payment mb-4 flex-wrap">
                <div class="w-100 d-flex">
                  <p class="mb-0 pe-4">
                    Hasta
                    {{ dataCartAnonymous.carrito.envio.dias_entrega }} días
                    hábiles, lo recibes entre el
                    {{
                      dataCartAnonymous.carrito.envio.fechasEnvio.fechaInicio
                    }}
                    y el
                    {{
                      dataCartAnonymous.carrito.envio.fechasEnvio.fechaFinal
                    }}.
                  </p>
                  <p class="mb-0 bold">
                    ${{
                      new Intl.NumberFormat('es').format(
                        dataCartAnonymous.carrito.envio.envio_neto,
                      )
                    }}
                  </p>
                </div>
                <div
                  v-if="dataCartAnonymous.carrito.infoEstrategiaVentas"
                  class="w-100 mt-2"
                >
                  <div
                    class="p-2 rounded"
                    style="background-color: #f7f7f7; display: inline-block;"
                  >
                    <a
                      class="link-envio-gratis"
                      target="_blank"
                      :href="
                        dataCartAnonymous.carrito.infoEstrategiaVentas
                          .link_pagina_condiciones
                      "
                    >
                      Aplica <b>envío gratis</b>. Ver términos y condiciones.
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="stepPayment == 3" class="body-step-checkout">
            <address-checkout-anonymo :entregar-otra-dir="entregarOtraDir" />
          </div>
        </div>
        <div class="container-step-checkout">
          <div class="head-step-checkout">
            <div class="title-head-step-checout">
              <h5><span>04</span> Pago</h5>
            </div>
          </div>
          <div v-if="stepPayment == 4" class="body-step-checkout">
            <payment-checkout-anonymo @nextStep="stepPayment = 5" />
          </div>
        </div>
      </div>
      <div v-if="stepPayment == 5">
        <finish-payment-anonymo :step-payment="stepPayment" />
      </div>
    </div>
    <div v-else class="w-100 ps-0 ps-md-5">
      <skeleton-checkout />
    </div>
    <checkout-detail-anonymo
      v-if="dataCartAnonymous && stepPayment != 5"
      :step-payment="stepPayment"
    />
    <b-modal
      v-model="shoeModalMaskData"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="position-relative p-4 text-center">
        <h4>Hola!</h4>
        <p class="fs-6">
          Este correo ya se encuentra registrado, completaremos el formulario
          automaticamente con tus datos.
        </p>
        <button
          class="btn-primary"
          @click="
            shoeModalMaskData = false
            validateStep()
          "
        >
          Continuar con la compra
        </button>
      </div>
    </b-modal>
    <b-modal
      id="modal-error-payment"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
    >
      <div class="container-modal-mask-data">
        <div
          class="icon-error-modal"
          :style="transaccion_rechazada == 1 ? '' : 'background-color: #ffeebf'"
        >
          <svg
            v-if="transaccion_rechazada == 1"
            class="mb-3"
            xmlns="http://www.w3.org/2000/svg"
            width="33.003"
            height="33.041"
            viewBox="0 0 33.003 33.041"
          >
            <g
              id="Icon_feather-check-circle"
              data-name="Icon feather-check-circle"
              transform="translate(-1.497 -1.473)"
            >
              <path
                id="Trazado_2544"
                data-name="Trazado 2544"
                d="M33,16.62V18A15,15,0,1,1,24.105,4.29"
                fill="none"
                stroke="#F67777"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
              />
              <path
                id="Trazado_2545"
                data-name="Trazado 2545"
                d="M27.213,6,18,15.222"
                transform="translate(-4.106 6.896)"
                fill="none"
                stroke="#F67777"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
              />
              <path
                id="Trazado_2578"
                data-name="Trazado 2578"
                d="M9.213,0,0,9.223"
                transform="translate(23.112 12.901) rotate(90)"
                fill="none"
                stroke="#F67777"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
              />
            </g>
          </svg>
          <img v-else src="@/assets/icons/icon-alert_triangle.svg" width="33" />
          <p
            class="my-0"
            style="font-weight: bold; font-size: 20px;"
            v-html="getStatusTransacion?.title"
          />
        </div>
        <p v-html="getStatusTransacion?.description" />
        <button class="btn-primary" @click="validateActionButtonTransaction()">
          {{ getStatusTransacion?.button }}
        </button>
      </div>
    </b-modal>

    <b-modal
      id="modal-proccess-payment"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
    >
      <div class="container-modal-mask-data p-4">
        <h5 class="mb-3">
          Hemos identificado una transacción en progreso
        </h5>
        <p>
          Es necesario esperar el resultado de esta transacción, antes de volver
          a intentar con otro medio de pago.
        </p>
        <button
          class="btn-primary"
          @click="$bvModal.hide('modal-proccess-payment')"
        >
          Entendido
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { GA4CheckoutBeginAnonymous, GA4AddShipingAnonymous } from '@/GA4/events'

import SkeletonCheckout from '@/components/Checkout/SkeletonCheckout.vue'
import CheckoutDetailAnonymo from '@/components/Checkout/CheckoutAnonymo/CheckoutDetailAnonymo.vue'
import EmailCheckoutAnonymo from '@/components/Checkout/CheckoutAnonymo/EmailCheckoutAnonymo.vue'
import InformationCheckoutAnonymo from '@/components/Checkout/CheckoutAnonymo/InformationCheckoutAnonymo.vue'
import AddressCheckoutAnonymo from '@/components/Checkout/CheckoutAnonymo/AddressCheckoutAnonymo.vue'
import PaymentCheckoutAnonymo from '@/components/Checkout/CheckoutAnonymo/PaymentCheckoutAnonymo.vue'
import FinishPaymentAnonymo from '@/components/Payment/StepsAnonymo/FinishPaymentAnonymo.vue'
export default {
  components: {
    CheckoutDetailAnonymo,
    EmailCheckoutAnonymo,
    InformationCheckoutAnonymo,
    AddressCheckoutAnonymo,
    PaymentCheckoutAnonymo,
    FinishPaymentAnonymo,
    SkeletonCheckout,
  },
  data() {
    return {
      stepPayment: 0,
      shoeModalMaskData: false,
      entregarOtraDir: false,
      transaccion_rechazada: 1,
    }
  },
  computed: {
    ...mapState('layouts', ['menuLayoutDerek']),
    ...mapState('payment', ['dataCartAnonymous']),
    ...mapState(['showModal423', 'dataModal423']),
    stepGeneral() {
      if (
        this.dataCartAnonymous &&
        this.dataCartAnonymous.carrito.cliente_correo != ''
      ) {
        if (
          this.dataCartAnonymous.carrito.cliente_nombre != '' &&
          this.dataCartAnonymous.carrito.cliente_apellidos != '' &&
          this.dataCartAnonymous.carrito.tipodoc != '' &&
          this.dataCartAnonymous.carrito.cliente_documento != '' &&
          this.dataCartAnonymous.carrito.cliente_celular != ''
        ) {
          if (
            this.dataCartAnonymous.carrito.cliente_direccion != '' &&
            this.dataCartAnonymous.carrito.cliente_barrio != '' &&
            this.dataCartAnonymous.carrito.cliente_departamento != '' &&
            this.dataCartAnonymous.carrito.cliente_ciudad != ''
          ) {
            return 4
          } else {
            return 3
          }
        } else {
          return 2
        }
      } else {
        return 1
      }
    },
    getStatusTransacion() {
      return {
        title:
          this.transaccion_rechazada == 1
            ? 'No fue posible procesar tu pago'
            : 'Transacción en proceso',
        description:
          this.transaccion_rechazada == 1
            ? `Tu compra no ha finalizado, parece que hubo un problema en la
              autorización de la transacción. <br /><br />
              intenta realizar el pago nuevamente.`
            : `Tu pago está siendo procesado por nuestro sistema. Esto puede tomar unos minutos. <br /> <br />
              Por favor, no realices otro intento de pago. Te notificaremos por correo electrónico cuando se confirme la transacción.`,
        button:
          this.transaccion_rechazada == 1
            ? 'Seleccionar otro medio de pago'
            : 'Volver al inicio',
      }
    },
  },
  watch: {
    dataCartAnonymous() {
      if (
        this.dataCartAnonymous &&
        this.dataCartAnonymous.carrito.carrito_bloqueado == 1
      ) {
        this.$bvModal.show('modal-proccess-payment')
      }
      if (this.$route.params.tokenCart) {
        this.watchRouterCheckout()
        if (!this.sendAnalitycs && this.dataCartAnonymous) {
          // this.$gtag.event('begin_checkout', {
          //   coupon: this.dataCartAnonymous.carrito.cupon,
          //   currency: 'COP',
          //   items: this.cart,
          //   value: this.dataCartAnonymous.carrito.carrito_subtotal,
          // })
          this.sendAnalitycs = true
        }
        if (this.stepPayment != 1) {
          this.validateStep()
        }
      } else {
        this.$router.push({ name: 'Home' })
      }
    },
    stepPayment() {
      let query = Object.assign({}, this.$route.query)
      if (this.stepPayment == 1) {
        this.$router
          .replace({ name: 'PaymentEmailAnonymous', query: query })
          .catch(() => {})
      } else if (this.stepPayment == 2) {
        this.$router
          .replace({ name: 'PaymentProfileAnonymous', query: query })
          .catch(() => {})
      } else if (this.stepPayment == 3) {
        this.$router
          .replace({ name: 'PaymentShippingAddressAnonymous', query: query })
          .catch(() => {})
      } else if (this.stepPayment == 4) {
        this.$router
          .replace({ name: 'PaymentAnonymous', query: query })
          .catch(() => {})
      } else if (this.stepPayment == 5) {
        this.$router
          .replace({ name: 'PaymentSummaryAnonymous', query: query })
          .catch(e => {
            console.log(e)
          })
      }
      if (this.stepPayment > 1) {
        window.sendinblue.identify(
          this.dataCartAnonymous.carrito.cliente_correo,
        )
        GA4CheckoutBeginAnonymous()
      }
      if (this.stepPayment > 3) {
        GA4AddShipingAnonymous()
      }
    },
  },
  beforeCreate() {
    this.$store.state.layouts.menuLayoutDerek = 'menu-simple'
    this.$store.state.layouts.footerLayoutDerek = 'footer-simple'
  },
  async mounted() {
    this.$store.state.layouts.menuLayoutDerek = 'menu-simple'
    this.$store.state.layouts.footerLayoutDerek = 'footer-simple'
    document.title = 'Checkout | DEREK Tienda Online'
    if (this.$route.params.tokenCart) {
      await this.getProductsAnonymous({ token: this.$route.params.tokenCart })
      if (this.dataCartAnonymous) {
        if (
          this.dataCartAnonymous.carrito.cliente_correo &&
          !this.$route.params.changeEmail
        ) {
          this.haveEmail = true
          document.title = 'Checkout datos | DEREK Tienda Online'
        } else {
          document.title = 'Checkout email | DEREK Tienda Online'
        }
      }
    } else {
      this.$router.push({ name: 'Home' })
    }
    this.validateStep()
    if (this.$route.params.showmodalPaymentDecline) {
      this.transaccion_rechazada = this.$route.params.transaccion_rechazada ?? 1
      this.$bvModal.show('modal-error-payment')
    }
  },
  destroyed() {
    this.changeMenu({ menu: 'menu-base' })
    this.changeFooter({ footer: 'footer-derek' })
  },
  methods: {
    ...mapActions('layouts', ['changeMenu', 'changeFooter']),
    ...mapActions('payment', ['getProductsAnonymous']),
    validateStep() {
      if (
        this.dataCartAnonymous &&
        this.dataCartAnonymous.carrito.cliente_correo != ''
      ) {
        if (
          this.dataCartAnonymous.carrito.cliente_nombre != '' &&
          this.dataCartAnonymous.carrito.cliente_apellidos != '' &&
          this.dataCartAnonymous.carrito.tipodoc != '' &&
          this.dataCartAnonymous.carrito.cliente_documento != '' &&
          this.dataCartAnonymous.carrito.cliente_celular != ''
        ) {
          if (
            this.dataCartAnonymous.carrito.cliente_direccion != '' &&
            this.dataCartAnonymous.carrito.cliente_departamento != '' &&
            this.dataCartAnonymous.carrito.cliente_ciudad != ''
          ) {
            this.stepPayment = 4
          } else {
            this.stepPayment = 3
          }
        } else {
          this.stepPayment = 2
        }
      } else {
        if (this.dataCartAnonymous) {
          this.stepPayment = 1
        }
      }
    },
    watchRouterCheckout() {
      let query = Object.assign({}, this.$route.query)
      if (
        this.dataCartAnonymous.carrito &&
        this.dataCartAnonymous.carrito.cliente_correo != ''
      ) {
        if (
          this.dataCartAnonymous.carrito.cliente_nombre != '' &&
          this.dataCartAnonymous.carrito.cliente_apellidos != '' &&
          this.dataCartAnonymous.carrito.tipodoc != '' &&
          this.dataCartAnonymous.carrito.cliente_documento != '' &&
          this.dataCartAnonymous.carrito.cliente_celular != ''
        ) {
          if (
            this.dataCartAnonymous.carrito.cliente_direccion != '' &&
            this.dataCartAnonymous.carrito.cliente_departamento != '' &&
            this.dataCartAnonymous.carrito.cliente_ciudad != ''
          ) {
            this.$router
              .replace({ name: 'PaymentAnonymous', query: query })
              .catch(() => {})
          } else {
            this.$router
              .replace({
                name: 'PaymentShippingAddressAnonymous',
                query: query,
              })
              .catch(() => {})
          }
        } else {
          this.$router
            .replace({ name: 'PaymentProfileAnonymous', query: query })
            .catch(() => {})
        }
      } else {
        this.$router
          .replace({ name: 'PaymentEmailAnonymous', query: query })
          .catch(() => {})
      }
    },
    validateActionButtonTransaction() {
      if (this.transaccion_rechazada == 1) {
        this.$bvModal.hide('modal-error-payment')
      } else {
        this.$router.replace({ name: 'Home' }).catch(() => {})
      }
    },
    // validate423() {
    //   if (this.dataModal423.routeName == 'PaymentEmail') {
    //     location.reload()
    //   } else {
    //     this.$router.push({ name: this.dataModal423.routeName })
    //   }
    // },
  },
}
</script>
