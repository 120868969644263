var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-3 d-flex flex-wrap w-100"},[_c('div',{},[_c('router-link',{attrs:{"to":{
        name: 'ProductDetail',
        params: {
          product: _vm.product.url.replace('product-', ''),
          vitrinaId: 'producto-devolucion',
          vitrinaName: 'producto de devolución',
        },
      }}},[_c('img',{staticClass:"img-product-return-order",attrs:{"src":_vm.product.producto_imagen,"alt":""}})])],1),_c('div',{staticClass:"data-product-return-order"},[_c('p',{staticClass:"title-product-return-order"},[_vm._v(" "+_vm._s(_vm.product.titulo.toLowerCase().replace(/^\w/, d => d.toUpperCase()))+" ")]),_c('span',{staticClass:"detail-product-return-order"},[_vm._v("SKU: "+_vm._s(_vm.product.producto_sku)+" "),_c('br'),_vm._v(" Talla: "+_vm._s(_vm.product.talla)+" "),_c('br'),_vm._v(" Color: "+_vm._s(_vm.product.color.toLowerCase().replace(/^\w/, d => d.toUpperCase()))+" "),_c('br')]),(_vm.product.facturado == 0)?_c('span',{staticStyle:{"padding":".1rem .3rem","border":"1px solid tomato","color":"tomato","border-radius":"3px"}},[_vm._v("Cancelado")]):_c('p',{staticClass:"price-product-return-order"},[_vm._v(" $"+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.product.precio))+" ")])]),_c('div',{staticClass:"mt-3"},[(_vm.product.devolucion)?_c('span',{staticStyle:{"padding":".1rem .3rem","background-color":"#C8D7E5","border-radius":"3px","font-family":"'PT Sans Bold', sans-serif","margin-bottom":".3rem","margin-right":"1rem"}},[_vm._v("Devolución")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }