<template>
  <div class="store-component">
    <div class="breadcrumb-derek">
      <router-link
        v-if="$route.name != 'Store'"
        :to="{
          name: 'Store',
        }"
        class="navigate-to"
      >
        Inicio
      </router-link>
      <router-link
        v-if="$route.params.subcategory"
        :to="{
          name: 'TiendaCategory',
          params: {
            category: $route.params.category,
          },
        }"
        class="navigate-to"
      >
        {{ $route.params.category }}
      </router-link>
      <p class="focus-page">
        {{ titleStore }}
      </p>
    </div>
    <header-page-derek
      :title="titleStore"
      :description="descriptionStore"
      :skeleton="productsCategory == null"
    />
    <div class="store-content">
      <nav-filters-desktop
        :grid-display="gridDisplay"
        @changeGridDisplay="gridDisplay = $event"
      />
      <div class="d-flex justify-content-end me-3" style="min-height: 30px">
        <div class="d-none d-md-flex justify-content-end pe-2">
          <img
            v-if="gridDisplay == 6"
            title="cambiar vista de productos"
            src="@/assets/icons/display-2-select.svg"
            class="pe-2"
            alt="imagen grid display 2"
            style="cursor: pointer; max-height: 20px"
          />
          <img
            v-else
            title="cambiar vista de productos"
            src="@/assets/icons/display-2.svg"
            class="pe-2"
            alt="imagen grid display 2"
            style="cursor: pointer; max-height: 20px"
            @click="gridDisplay = 6"
          />
          <img
            v-if="gridDisplay === 4"
            title="cambiar vista de productos"
            src="@/assets/icons/display-3-select.svg"
            class="pe-2"
            alt="imagen grid display 3"
            style="cursor: pointer; max-height: 20px"
          />
          <img
            v-else
            title="cambiar vista de productos"
            src="@/assets/icons/display-3.svg"
            class="pe-2"
            alt="imagen grid display 3"
            style="cursor: pointer; max-height: 20px"
            @click="gridDisplay = 4"
          />
        </div>
        <div class="d-flex d-md-none">
          <img
            v-if="gridDisplay == 6"
            src="@/assets/icons/display-1.svg"
            class="img-grid-filter-movile"
            alt="imagen grid display 1"
            @click="gridDisplay = 12"
          />
          <img
            v-if="gridDisplay == 12"
            src="@/assets/icons/display-2-select.svg"
            class="img-grid-filter-movile"
            alt="imagen grid display 2"
            @click="gridDisplay = 6"
          />
        </div>
      </div>
      <div id="products-table">
        <filters-selected />
        <div v-if="productsCategory" class="products-store">
          <div class="grid-products">
            <div
              v-for="(product, index) in productsCategory.data.slice(
                0,
                gridDisplay == 4
                  ? productStrategia
                    ? 2
                    : 3
                  : productStrategia
                  ? 1
                  : 2,
              )"
              :key="index"
              class="products-store-grid"
              :class="'col-' + gridDisplay"
              @click="clicProduct(productsAnalytics, product.producto_sku)"
            >
              <card-product-store
                :product="product"
                :vitrina-id="'store-page'"
                :vitrina-name="titleStore"
              />
            </div>
            <div
              v-if="productStrategia"
              class="pe-3 products-store-grid"
              :class="'col-' + gridDisplay"
            >
              <div class="h-100" style="background-color: white">
                <a :href="productStrategia.url_analytics">
                  <img
                    loading="lazy"
                    :src="productStrategia.banner_movil"
                    :alt="''"
                    class="d-flex d-md-flex img-product-store"
                  />
                  <img
                    loading="lazy"
                    :src="productStrategia.banner_full"
                    :alt="''"
                    class="d-none d-md-none img-product-store"
                  />
                </a>
                <div
                  class="d-flex flex-wrap justify-content-between align-items-center px-3 mt-1 pt-4"
                >
                  <div class="col-12 col-md-8 content-box-estrategia">
                    <p class="fs-6 mb-0 ">
                      <b v-html="productStrategia.nombre"></b>
                    </p>
                    <div>
                      <p class="show-more-estrategia">Ver más</p>
                    </div>
                    <span
                      class="d-none d-md-flex"
                      v-html="productStrategia.descripcion"
                    ></span>
                  </div>
                  <div class="col-12 col-md-4 d-none d-md-flex">
                    <a
                      class="ms-auto w-100"
                      :href="productStrategia.url_analytics"
                    >
                      <button
                        style="font-size: .875rem"
                        class="w-100 px-3 edit-data-perfil-button"
                      >
                        Ver más
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-products">
            <div
              v-for="(product, index) in productsCategory.data.slice(
                gridDisplay == 4
                  ? productStrategia
                    ? 2
                    : 3
                  : productStrategia
                  ? 1
                  : 2,
                gridDisplay == 4
                  ? productStrategia
                    ? 2
                    : 3
                  : productStrategia
                  ? 1
                  : 2,
              )"
              :id="`product-${product.producto_sku}-${product.color}`"
              :key="index + gridDisplay + 'estrategia'"
              class="products-store-grid"
              :class="'col-' + gridDisplay"
              @click="clicProduct(productsAnalytics, product.producto_sku)"
            >
              <card-product-store
                :product="product"
                :vitrina-id="'store-page'"
                :vitrina-name="titleStore"
              />
            </div>
          </div>
          <div class="banner-store-cupo">
            <breakin-cupo />
          </div>
          <div class="grid-products">
            <div
              v-for="(product, index) in productsCategory.data.slice(
                gridDisplay == 4
                  ? productStrategia
                    ? 2
                    : 3
                  : productStrategia
                  ? 1
                  : 2,
                productsCategory.data.length,
              )"
              :id="`product-${product.producto_sku}-${product.color}`"
              :key="index + gridDisplay + 'update'"
              class="products-store-grid"
              :class="'col-' + gridDisplay"
              @click="clicProduct(productsAnalytics, product.producto_sku)"
            >
              <card-product-store
                :product="product"
                :vitrina-id="'store-page'"
                :vitrina-name="titleStore"
              />
            </div>
          </div>
        </div>
        <div v-else class="grid-products">
          <div class="products-store w-100">
            <skeleton-img
              v-for="item in 45"
              :key="item"
              :class="'col-' + gridDisplay"
            />
          </div>
        </div>
      </div>
      <div v-if="productsCategory" class="py-4">
        <b-pagination
          v-if="productsCategory.total > productsCategory.per_page"
          v-model="currentPage"
          class="pagination-comment"
          :total-rows="productsCategory.total"
          :per-page="productsCategory.per_page"
          aria-controls="products-table"
          align="center"
          :hide-goto-end-buttons="true"
        ></b-pagination>
      </div>
    </div>
    <modal-news-latter />
  </div>
</template>
<script>
import { BPagination } from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import { GA4SelectPromotion, GA4ViewItemList } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'

import BreakinCupo from '@/components/Templates/BreakinCupo.vue'
import SkeletonImg from '@/components/Templates/SkeletonImg.vue'
import FiltersSelected from '@/components/store/FiltersSelected.vue'
import NavFiltersDesktop from '@/components/store/NavFiltersDesktop.vue'
import HeaderPageDerek from '@/components/Templates/HeaderPageDerek.vue'
import CardProductStore from '@/components/Templates/CardProductStore.vue'
import ModalNewsLatter from '@/components/Templates/ModalNewsLatter.vue'

export default {
  components: {
    NavFiltersDesktop,
    CardProductStore,
    BreakinCupo,
    HeaderPageDerek,
    SkeletonImg,
    BPagination,
    FiltersSelected,
    ModalNewsLatter,
  },
  data() {
    return {
      gridDisplay: 4,
      currentPage: 1,
      GA4ViewProduct: [],
      sendGA4ViewProduct: [],
    }
  },
  computed: {
    ...mapState('products', [
      'productsCategory',
      'productStrategia',
      'productsAnalytics',
      'dataCatalogoAnalytics',
      'titleStore',
      'descriptionStore',
    ]),
    ...mapState('user', ['dataUser']),
  },
  watch: {
    productsCategory() {
      if (this.productsCategory) {
        this.sendGA4ViewProduct = this.productsCategory.data.slice(
          0,
          this.gridDisplay == 4
            ? this.productStrategia
              ? 2
              : 3
            : this.productStrategia
            ? 1
            : 2,
        )
        if (this.$route.params.nameCreative) {
          const GA4Products = []
          this.productsCategory.data.forEach(product =>
            GA4Products.push({
              item_name: product.producto_titulo,
              item_id: product.producto_sku,
              price: parseInt(product.producto_precio),
              affiliation: 'Derek',
              item_brand: product.marca ? product.marca : 'Derek',
              item_category: product.categoria,
              item_category2: product.subcategoria,
              item_list_id: this.$route.params.id ? this.$route.params.id : '',
              item_variant: product.color,
              discount:
                parseInt(product.producto_precio_antes) > 0
                  ? parseInt(product.producto_precio_antes) -
                    parseInt(product.producto_precio)
                  : 0,
              promotion_name: product.descuento ? product.descuento : '',
              quantity: 1,
            }),
          )
          GA4SelectPromotion({
            nameCreative: this.$route.params.nameCreative,
            slot: this.$route.params.slot,
            id: this.$route.params.id,
            name: this.$route.params.name,
            items: GA4Products,
          })
        }
      }
    },
    $route(to, from) {
      if (to.fullPath != from.fullPath) {
        if (to.query.page) {
          this.fetchProductsCategory()
        } else {
          this.fetchProductsCategory()
          this.getFiltersStore()
        }
      }
      if (to.query.page) {
        this.currentPage = to.query.page
      } else {
        this.currentPage = 1
      }
      GA4ViewItemList(this.sendGA4ViewProduct, 'store-page', this.titleStore)
    },
    currentPage() {
      let query = Object.assign({}, this.$route.query)
      if (query.page != this.currentPage) {
        if (this.currentPage == 1) {
          delete query.page
          this.$router.push({ query: query }).catch(() => {
            location.reload()
          })
        } else {
          query.page = this.currentPage
          this.$router.push({ query: query }).catch(() => {
            location.reload()
          })
        }
        window.scrollTo(0, 0)
      }
    },
    productsAnalytics() {
      let arraySkus = []
      this.productsAnalytics.forEach(e => {
        arraySkus.push(e.id)
      })
      if (arraySkus) {
        const misCookies = document.cookie.split(';')
        let email = misCookies.find(e => {
          return e.includes('email')
        })
        var deviceType = /iPad/.test(navigator.userAgent)
          ? 't'
          : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
              navigator.userAgent,
            )
          ? 'm'
          : 'd'
        window.criteo_q.push(
          { event: 'setAccount', account: 67539 },
          {
            event: 'setEmail',
            email: email
              ? email.replace('email=', '')
              : this.dataUser
              ? this.dataUser.correo
              : '',
          },
          { event: 'setSiteType', type: deviceType },
          { event: 'setZipcode', zipcode: '##Zip Code##' },
          {
            event: 'viewList',
            item: arraySkus,
            category: this.titleStore,
            keywords: this.descriptionStore,
          },
        )
      }
    },
  },
  beforeDestroy() {
    GA4ViewItemList(this.sendGA4ViewProduct, 'store-page', this.titleStore)
  },
  created() {
    sendFBQEvent(this, 'PageView', {})
    document.title = 'Store | DEREK Tienda Online'
    if (window.innerWidth < 768) {
      this.gridDisplay = 6
    }
    if (this.$route.query.page) {
      this.currentPage = this.$route.query.page
    }
    this.fetchProductsCategory()
    this.getFiltersStore()
    window.addEventListener('scroll', this.listenScroll)
    window.onbeforeunload = function() {
      alert('Mensaje')
    }
  },
  methods: {
    ...mapActions('products', ['getProductsCategory', 'getFiltersCategory']),
    fetchProductsCategory() {
      let query = this.$route.fullPath.split('?')[1]
        ? '?' + this.$route.fullPath.split('?')[1]
        : ''
      let category = this.$route.params.category
        ? this.$route.params.category
        : ''
      let subCategory = this.$route.params.subcategory
        ? this.$route.params.subcategory
        : ''
      let wildCard = this.$route.params.wildcard
        ? this.$route.params.wildcard
        : ''
      this.getProductsCategory({
        category: category,
        subcategory: subCategory,
        wildCard: wildCard,
        query: query,
      })
    },
    getFiltersStore() {
      let query = this.$route.fullPath.split('?')[1]
        ? '?' + this.$route.fullPath.split('?')[1]
        : ''
      let category = this.$route.params.category
        ? this.$route.params.category
        : ''
      let subCategory = this.$route.params.subcategory
        ? this.$route.params.subcategory
        : ''
      let wildCard = this.$route.params.wildcard
        ? this.$route.params.wildcard
        : ''
      this.getFiltersCategory({
        category: category,
        subcategory: subCategory,
        wildCard: wildCard,
        query: query,
      })
    },
    clicProduct(dataAnalitycs, skuSearch) {
      let productAnalitycs = dataAnalitycs?.find(e => e.id == skuSearch)
      if (productAnalitycs) {
        // DK-ProductClick
        // window.dataLayer.push({
        //   event: 'DK-ProductClick',
        //   name: productAnalitycs.name,
        //   id: productAnalitycs.id,
        //   price: productAnalitycs.price,
        //   brand: productAnalitycs.brand,
        //   category: productAnalitycs.category,
        //   variant: productAnalitycs.variant,
        //   position: productAnalitycs.position,
        // })
      }
    },
    listenScroll() {
      if (this.productsCategory) {
        this.productsCategory.data.forEach(product => {
          let cardProduct = document.getElementById(
            `product-${product.producto_sku}-${product.color}`,
          )
          if (
            cardProduct &&
            !this.GA4ViewProduct.includes(
              `${product.producto_sku}-${product.color}`,
            ) &&
            cardProduct.getBoundingClientRect().top -
              (window.innerHeight - cardProduct.offsetHeight / 2) <=
              0
          ) {
            this.GA4ViewProduct.push(`${product.producto_sku}-${product.color}`)
            this.sendGA4ViewProduct.push(product)
          }
        })
      }
    },
  },
}
</script>
