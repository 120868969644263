<template>
  <div :id="'order-detail'" class="follow-up-order">
    <div class="content-return-order">
      <span class="label-state" style="background-color: #C8D5EF">{{
        getStepOrder(dataOrder.step)
      }}</span>
      <div class="header-return-order">
        <div>
          <p>
            Pedido No. <strong>{{ dataOrder.pedido_id }}</strong> <br />
            Fecha pedido: <strong>{{ getDate(dataOrder.pedido_fecha) }}</strong
            ><br />
            Precio:
            <strong
              >${{
                new Intl.NumberFormat('de-DE').format(dataOrder.pedido_total)
              }}</strong
            ><br />
            Último estado:
            <strong
              >{{ dataOrder['step_title_' + dataOrder.step] }} -
              {{
                dataOrder.fecha_ultimo_estado
                  ? dataOrder.fecha_ultimo_estado
                  : ''
              }}</strong
            >
          </p>
        </div>
        <div class="col-12 col-md-4 col-xl-3 d-flex justify-content-end">
          <div
            v-if="dataOrder.pedido_entregado > 0 && !dataOrder.devolucion"
            class="w-100 justify-content-end d-flex"
          >
            <router-link
              style="text-decoration: none; color: #0076FF; font-size: 1rem;"
              :to="{
                name: 'Returns',
                params: { idPedido: dataOrder.pedido_id },
              }"
            >
              <button
                v-if="dataOrder.pedido_entregado > 0 && !dataOrder.devolucion"
                :to="{
                  name: 'Returns',
                  params: { idPedido: dataOrder.pedido_id },
                }"
                class="btn-estado-pedido"
              >
                Hacer una devolución
              </button>
            </router-link>
          </div>
          <div v-else class="text-end">
            <p class="mb-0 fs-6 bold">¿Necesitas ayuda?</p>
            <a
              style="color: black; font-size: 1rem"
              target="_blank"
              href="https://api.whatsapp.com/send?phone=573232747474&text=Hola!%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20producto"
              >Whatsapp 323 274 7474</a
            >
          </div>
        </div>
      </div>
      <div class="container-info-devolucion">
        <div class="steps-order">
          <p :class="dataOrder.step >= 1 ? 'steps-order-selected' : ''">
            1. {{ dataOrder.step_title_1 }}
          </p>
          <span>></span>
          <p :class="dataOrder.step >= 2 ? 'steps-order-selected' : ''">
            2. {{ dataOrder.step_title_2 }}
          </p>
          <span>></span>
          <p
            :class="dataOrder.step >= 3 ? 'steps-order-selected' : ''"
            style="min-width: 190px;"
          >
            3. {{ dataOrder.step_title_3 }}
          </p>
          <span>></span>
          <p :class="dataOrder.step >= 4 ? 'steps-order-selected' : ''">
            4. {{ dataOrder.step_title_4 }}
          </p>
        </div>
      </div>
      <div class="d-flex d-md-none">
        <button
          class="btn-estado-pedido"
          @click="showMoreInfoMovil = !showMoreInfoMovil"
        >
          Ver {{ showMoreInfoMovil ? 'menos' : 'más' }} detalles del pedido
        </button>
      </div>
      <div
        class="summary-follow-order"
        :style="
          showMoreInfoMovil
            ? ''
            : 'height: 0; overflow: hidden; margin: 0; padding: 0'
        "
      >
        <div class="data-shipping-follow-order col-12 col-md-4">
          <p class="mb-1"><strong>Datos de envío</strong></p>
          <p class="mb-0">
            {{ dataOrder.pedido_cliente_nombre }}
            {{ dataOrder.pedido_cliente_apellidos }}<br />
            {{ dataOrder.pedido_direccion_entrega }}<br />
            {{ dataOrder.observaciones }}<br v-if="dataOrder.observaciones" />
            {{
              dataOrder.pedido_ciudad
                ? `${dataOrder.pedido_ciudad
                    .split(', ')[0]
                    .toLowerCase()
                    .replace(/^\w/, c =>
                      c.toUpperCase(),
                    )}, ${dataOrder.pedido_ciudad
                    .split(', ')[1]
                    .toLowerCase()
                    .replace(/^\w/, c => c.toUpperCase())}`
                : dataOrder.pedido_ciudad
            }}<br />
            {{ dataOrder.pedido_cliente_celular }}
          </p>
        </div>
        <div class="detail-pay-follow-order col-12 col-md-4 mb-4">
          <p class="mb-1"><strong>Detalles de pago</strong></p>
          <p class="mb-0 ">
            Metodo de pago: {{ dataOrder.metodo_pago }}
            <span v-if="dataOrder.pedido_bono_codigo"
              >- {{ dataOrder.pedido_bono_codigo }}</span
            ><br />
            Referencia de pago {{ dataOrder.pedido_reference_sale }} <br />
            Fecha de transacción:
            {{ dataOrder.pedido_fecha.split(' ')[0].split('-')[2] }} de
            {{
              getMounthByNumber(
                dataOrder.pedido_fecha.split(' ')[0].split('-')[1],
              )
            }}
            {{ dataOrder.pedido_fecha.split(' ')[0].split('-')[0] }}<br />
            Total del pedido: ${{
              new Intl.NumberFormat('de-DE').format(dataOrder.pedido_total)
            }}
            <br />
            Articulos: {{ dataOrder.productos.length }}
          </p>
        </div>
        <div class="detail-guia-follow-order col-12 col-md-4 mb-auto">
          <p class="mb-1"><strong>Seguimiento de pedido</strong></p>
          <p v-if="dataOrder.seguimiento_pedido?.transportadora">
            Transportadora: {{ dataOrder.seguimiento_pedido.transportadora }}
            <a
              v-if="dataOrder.seguimiento_pedido.numero_guia"
              target="_blank"
              :href="dataOrder.seguimiento_pedido.link_guia"
            >
              No. {{ dataOrder.seguimiento_pedido.numero_guia }}
            </a>
          </p>
          <p v-if="dataOrder.seguimiento_pedido?.estado_pedido">
            Estado pedido: {{ dataOrder.seguimiento_pedido.estado_pedido }}
          </p>
          <p
            v-if="
              dataOrder.seguimiento_pedido?.fecha_estimada_entrega &&
                !dataOrder.seguimiento_pedido?.fecha_entrega
            "
          >
            Fecha estimada de entrega:
            {{ dataOrder.seguimiento_pedido.fecha_estimada_entrega }}
          </p>
          <p v-if="dataOrder.seguimiento_pedido?.fecha_entrega">
            Fecha de entrega: {{ dataOrder.seguimiento_pedido.fecha_entrega }}
          </p>
        </div>
      </div>
      <div>
        <div class="display-table">
          <table>
            <thead>
              <tr>
                <th class="py-3">Producto</th>
                <th class="py-3">Color</th>
                <th class="py-3">Talla</th>
                <th class="py-3">Cantidad</th>
                <th class="py-3">Valor producto</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(product, index) in dataOrder.productos" :key="index">
                <td>
                  <div class="table-product-detail">
                    <router-link
                      :to="{
                        name: 'ProductDetail',
                        params: {
                          product: product.url.replace('product-', ''),
                          vitrinaId: 'seguimiento-de-pedido-page-products',
                          vitrinaName: 'Productos del seguimiento de pedido',
                        },
                        query: { color: product.color_id },
                      }"
                    >
                      <img :src="product.producto_imagen" alt="" />
                    </router-link>
                    <div class="d-flex flex-wrap align-items-center">
                      <span
                        v-if="product.devolucion"
                        style="padding: .1rem .3rem; background-color: #C8D7E5; border-radius: 3px; font-family: 'PT Sans Bold', sans-serif; margin-bottom: .3rem; margin-right: 1rem"
                        >Devolución</span
                      >
                      <p class="mb-0 w-100 text-start">
                        {{
                          product.titulo
                            .toLowerCase()
                            .replace(/^\w/, c => c.toUpperCase())
                        }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  {{
                    product.color
                      .toLowerCase()
                      .replace(/^\w/, c => c.toUpperCase())
                  }}
                </td>
                <td>
                  {{
                    product.talla
                      .toLowerCase()
                      .replace(/^\w/, c => c.toUpperCase())
                  }}
                </td>
                <td>
                  1
                </td>
                <td>
                  <span
                    v-if="product.facturado == 0"
                    style="padding: .1rem .3rem; border: 1px solid tomato; color: tomato; border-radius: 3px; margin-bottom: .3rem"
                    >Cancelado</span
                  >
                  <b v-else>
                    ${{ new Intl.NumberFormat('de-DE').format(product.precio) }}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="d-block d-md-none mt-5 px-0">
        <div
          v-for="(product, index) in dataOrder.productos"
          :key="index"
          class="container-card-devolucion"
        >
          <simple-card-product :product="product" />
        </div>
      </div>
      <div>
        <div class="price-pedido-follow-order">
          <div class="price-pedido-follow-order">
            <div
              class="mr-auto mt-5 px-0 px-md-auto mt-md-0 col-12 col-md-auto order-2 order-md-1"
            >
              <div
                v-if="dataOrder.pedido_entregado > 0 && !dataOrder.devolucion"
              >
                <p class="">
                  <strong>Puedes realizar una devolución por pedido</strong>
                </p>
                <router-link
                  style="text-decoration: none; color: #0076FF; font-size: 1rem"
                  :to="{
                    name: 'Returns',
                    params: { idPedido: dataOrder.pedido_id },
                  }"
                >
                  <button class="btn-estado-pedido btn-follow-order px-4">
                    Hacer una devolución
                  </button>
                </router-link>
              </div>
            </div>
            <table class="mt-2 mt-md-0 order-1 order-md-2">
              <tbody>
                <tr>
                  <td>
                    <strong>Subtotal: </strong>
                  </td>
                  <td class="ps-4 text-end">
                    ${{
                      new Intl.NumberFormat('de-DE').format(
                        dataOrder.pedido_subtotal,
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Descuentos: </strong>
                  </td>
                  <td class="ps-4 text-end">
                    {{
                      dataOrder.pedido_descuento > 0
                        ? '$' +
                          new Intl.NumberFormat('de-DE').format(
                            dataOrder.pedido_descuento,
                          )
                        : '-'
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Envío: </strong>
                  </td>
                  <td class="ps-4 text-end">
                    {{
                      dataOrder.pedido_envio > 0
                        ? '$' +
                          new Intl.NumberFormat('de-DE').format(
                            dataOrder.pedido_envio,
                          )
                        : 'Envío gratis'
                    }}
                  </td>
                </tr>
                <tr
                  v-if="
                    dataOrder.pedido_bono_valor && dataOrder.pedido_bono_codigo
                  "
                >
                  <td>
                    <b>Bono regalo: </b>
                  </td>
                  <td class="ps-4 text-end">
                    {{
                      '-$' +
                        new Intl.NumberFormat('de-DE').format(
                          dataOrder.pedido_bono_valor,
                        )
                    }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <hr class="my-2" />
                  </td>
                  <td>
                    <hr class="my-2" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>TOTAL: </strong>
                  </td>
                  <td class="ps-4 text-end">
                    ${{
                      new Intl.NumberFormat('de-DE').format(
                        dataOrder.pedido_total,
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SimpleCardProduct from '@/components/Templates/SimpleCardProduct'

import { mapState } from 'vuex'
export default {
  components: { SimpleCardProduct },
  data() {
    return {
      months: [
        { value: '01', text: 'Enero' },
        { value: '02', text: 'Febrero' },
        { value: '03', text: 'Marzo' },
        { value: '04', text: 'Abril' },
        { value: '05', text: 'Mayo' },
        { value: '06', text: 'Junio' },
        { value: '07', text: 'Julio' },
        { value: '08', text: 'Agosto' },
        { value: '09', text: 'Septiembre' },
        { value: '10', text: 'Octubre' },
        { value: '11', text: 'Noviembre' },
        { value: '12', text: 'Diciembre' },
      ],
      showMoreInfoMovil: true,
    }
  },
  computed: {
    /* eslint-disable */
    ...mapState('user', ['dataOrder']),
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.showMoreInfoMovil = false
    }
  },
  methods: {
    getDate(data) {
      let year = data.split(' ')[0].split('-')[0]
      let month = this.months.find(
        e => e.value == data.split(' ')[0].split('-')[1],
      )
      let day = data.split(' ')[0].split('-')[2]
      return day + ' ' + month.text + ' ' + year
    },
    linkNewTab(idPedido) {
      window.open(
        'https://mobile.servientrega.com/WebSitePortal/RastreoEnvioDetalle.html?Guia=' +
          idPedido,
        '_blank',
      )
    },
    getMounthByNumber(mount) {
      let dataReturn = ''
      switch (mount) {
        case '01':
          dataReturn = 'Enero'
          break
        case '02':
          dataReturn = 'Febrero'
          break
        case '03':
          dataReturn = 'Marzo'
          break
        case '04':
          dataReturn = 'Abril'
          break
        case '05':
          dataReturn = 'Mayo'
          break
        case '06':
          dataReturn = 'Junio'
          break
        case '07':
          dataReturn = 'Julio'
          break
        case '08':
          dataReturn = 'Agosto'
          break
        case '09':
          dataReturn = 'Septiembre'
          break
        case '10':
          dataReturn = 'Octubre'
          break
        case '11':
          dataReturn = 'Nombiembre'
          break
        case '12':
          dataReturn = 'Diciembre'
          break
        default: {
          dataReturn = mount
        }
      }
      return dataReturn
    },
    getStepOrder(step) {
      let dataReturn = ''
      switch (step) {
        case 1:
          dataReturn = this.dataOrder.step_title_1
          break
        case 2:
          dataReturn = this.dataOrder.step_title_2
          break
        case 3:
          dataReturn = this.dataOrder.step_title_3
          break
        case 4:
          dataReturn = this.dataOrder.step_title_4
          break
        case 5:
          dataReturn = this.dataOrder.step_title_5
          break
        default: {
          dataReturn = ''
        }
      }
      return dataReturn
    },
  },
}
</script>
