var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-card-carousel"},[_c('div',{staticClass:"carousel-container"},[(_vm.showModal)?_c('div',{staticClass:"show-modal-carousel w-100",on:{"click":function($event){return _vm.$bvModal.show('modal-product-detail-' + _vm.product.producto_sku)}}},[_c('img',{staticClass:"img-product-carousel",attrs:{"src":_vm.product.imagenes
            ? _vm.product.imagenes[0]
            : _vm.product.referencia.imagenes[0],"alt":_vm.product.producto_titulo}}),(_vm.product.imagenes && _vm.product.imagenes.length > 1)?_c('img',{staticClass:"img-product-carousel-hover",attrs:{"src":_vm.product.imagenes[1],"alt":_vm.product.producto_titulo}}):_vm._e()]):_c('router-link',{staticClass:"w-100",attrs:{"to":{
        name: 'ProductDetail',
        params: {
          product: _vm.product.url.replace('product-', ''),
          relatedProductGA4: _vm.$route.name == 'ProductDetail',
          referenciaGA4: _vm.location,
          vitrinaId: _vm.vitrinaId,
          vitrinaName: _vm.vitrinaName,
        },
        query: { color: _vm.product.color },
      }}},[_c('img',{staticClass:"img-product-carousel",attrs:{"src":_vm.product.imagenes
            ? _vm.product.imagenes[0]
            : _vm.product.referencia.imagenes[0],"alt":_vm.product.producto_titulo}}),(_vm.product.imagenes && _vm.product.imagenes.length > 1)?_c('img',{staticClass:"img-product-carousel-hover",attrs:{"src":_vm.product.imagenes[1],"alt":_vm.product.producto_titulo}}):_vm._e(),(!(_vm.product.tallas.length == 1 && _vm.product.tallas[0].id == 'UN'))?_c('div',{staticClass:"box-sizes-carousel mx-auto"},[_c('div',{staticClass:"d-flex flex-wrap justify-content-center"},_vm._l((_vm.product.tallas),function(item,index){return _c('router-link',{key:index,staticClass:"size",class:index == _vm.product.tallas.length - 1 ? 'me-0' : '',attrs:{"to":{
              name: 'ProductDetail',
              params: {
                product: _vm.product.url.replace('product-', ''),
                talla: item.id,
                vitrinaId: _vm.vitrinaId,
                vitrinaName: _vm.vitrinaName,
              },
              query: { color: _vm.product.color },
            }}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)]):_vm._e()]),_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap","position":"absolute","top":"0","left":"0"}},_vm._l((_vm.product.tags),function(tag,index){return _c('span',{key:index,staticClass:"store-product-tags",style:(`background-color: ${tag.color_fondo}; color: ${tag.color_texto}`)},[_vm._v(_vm._s(tag.tag))])}),0),(_vm.showModal)?_c('div',{staticStyle:{"position":"absolute","top":"0","right":"1rem","padding":".2rem","background-color":"white","cursor":"pointer"},on:{"click":function($event){return _vm.$bvModal.show('modal-product-detail-' + _vm.product.producto_sku)}}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-lupa.svg"),"alt":""}})]):_c('button-fav',{staticClass:"carousel-fav",attrs:{"data-product":_vm.product,"aria-label":"Add favorite"}}),_c('div',{staticClass:"d-flex px-4 py-4 justify-content-evenly w-100"},[(_vm.showModal)?_c('div',{staticClass:"d-flex flex-wrap w-100"},[_c('div',{staticClass:"col-8 text-title",class:_vm.opt2 ? 'col-12' : ''},[_c('p',{staticStyle:{"cursor":"pointer","display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical","overflow":"hidden","min-height":"38px"},on:{"click":function($event){return _vm.$bvModal.show('modal-product-detail-' + _vm.product.producto_sku)}}},[_vm._v(" "+_vm._s(_vm.product.producto_titulo .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")])]),(_vm.product.dia_siniva == 1)?_c('div',{staticClass:"col-4 text-price",class:_vm.opt2
              ? 'col-12 text-start pt-2 d-flex flex-wrap align-items-center justify-content-between'
              : ''},[_c('del',[_vm._v("$ "+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.product.producto_precio ? _vm.product.producto_precio : _vm.product.precio, )))]),_c('p',{staticClass:"text-end mt-1",staticStyle:{"line-height":".8","font-size":"1.1rem"}},[_c('b',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.product.producto_precio_siniva, )))]),_c('br'),_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v("*Precio sin IVA")])])]):_c('div',{staticClass:"col-4 text-price",class:_vm.opt2
              ? 'col-12 text-start pt-2 d-flex flex-wrap align-items-center justify-content-between'
              : ''},[_c('p',[_vm._v(" $ "+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.product.producto_precio ? _vm.product.producto_precio : _vm.product.precio, ))+" ")]),_c('del',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.product.producto_precio_antes
                ? _vm.product.producto_precio_antes > 0
                : _vm.product.precio_antes > 0
            ),expression:"\n              product.producto_precio_antes\n                ? product.producto_precio_antes > 0\n                : product.precio_antes > 0\n            "}]},[_vm._v("$ "+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.product.producto_precio_antes ? _vm.product.producto_precio_antes : _vm.product.precio_antes, )))])])]):_c('router-link',{staticClass:"d-flex w-100",attrs:{"to":{
          name: 'ProductDetail',
          params: {
            product: _vm.product.url.replace('product-', ''),
            vitrinaId: _vm.vitrinaId,
            vitrinaName: _vm.vitrinaName,
          },
          query: { color: _vm.product.color },
        }}},[_c('div',{staticClass:"col-8 text-title"},[_c('p',{staticStyle:{"display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical","overflow":"hidden","min-height":"38px"}},[_vm._v(" "+_vm._s(_vm.product.producto_titulo .toLowerCase() .replace(/^\w/, d => d.toUpperCase()))+" ")])]),(_vm.product.dia_siniva == 1)?_c('div',{staticClass:"col-4 text-price"},[_c('del',[_vm._v("$ "+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.product.producto_precio)))]),_c('p',{staticClass:"text-end mt-1",staticStyle:{"line-height":".8","font-size":"1.1rem"}},[_c('b',[_vm._v("$"+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.product.producto_precio_siniva, )))]),_c('br'),_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v("*Precio sin IVA")])])]):_c('div',{staticClass:"col-4 text-price"},[_c('p',[_vm._v(" $ "+_vm._s(new Intl.NumberFormat('de-DE').format(_vm.product.producto_precio))+" ")]),_c('del',{directives:[{name:"show",rawName:"v-show",value:(_vm.product.producto_precio_antes > 0),expression:"product.producto_precio_antes > 0"}]},[_vm._v("$ "+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.product.producto_precio_antes, )))])])])],1)],1),_c('modal-product-detail',{attrs:{"product":_vm.product}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }