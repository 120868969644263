var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-block d-md-flex"},[_c('div',{staticClass:"col-12 col-md-6 content-baner"},[(_vm.bannerHome.subtitulo)?_c('p',{style:(_vm.bannerHome.style_subtitulo),domProps:{"innerHTML":_vm._s(_vm.bannerHome.subtitulo)}}):_vm._e(),(_vm.bannerHome.titulo)?_c('h1',{staticClass:"text-baner",style:(_vm.bannerHome.style_titulo),domProps:{"innerHTML":_vm._s(_vm.bannerHome.titulo)}}):_vm._e(),(_vm.bannerHome.descripcion)?_c('p',{style:(_vm.bannerHome.style_descripcion),domProps:{"innerHTML":_vm._s(_vm.bannerHome.descripcion)}}):_vm._e(),_c('button',{staticClass:"btn-primary",staticStyle:{"font-size":"1.18rem"},style:('color: ' +
            _vm.bannerHome.boton.color_texto +
            '!important' +
            '; background-color: ' +
            _vm.bannerHome.boton.color_fondo +
            '!important'),on:{"click":function($event){return _vm.clicBtn(_vm.bannerHome.boton.texto)}}},[_vm._v(" "+_vm._s(_vm.bannerHome.boton.texto)+" ")])]),(_vm.bannerHome.tipo == 2)?_c('div',{staticClass:"d-none d-md-flex col-12 col-md-6 baner-large-video",on:{"click":function($event){return _vm.clicBtn('video ecritorio')}}},[_c('video',{staticClass:"h-100",attrs:{"autoplay":"","loop":"","playsinline":"","muted":"","src":_vm.bannerHome.imagen_desktop},domProps:{"muted":true}})]):_c('div',{staticClass:"d-none d-md-flex col-12 col-md-6 baner-large",style:('background-image: url(' +
          _vm.bannerHome.imagen_desktop +
          '); max-height: 972px; background-position: 50% top; cursor: pointer;'),on:{"click":function($event){return _vm.clicBtn('imagen escritorio')}}},[_c('a',{staticClass:"btn-banner-nav",staticStyle:{"position":"relative","color":"transparent"},attrs:{"href":"#nav-box"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"40","height":"40","viewBox":"0 0 50 50"}},[_c('g',{attrs:{"id":"Grupo_4776","data-name":"Grupo 4776","transform":"translate(-658 -718)"}},[_c('rect',{attrs:{"id":"Rectángulo_2036","data-name":"Rectángulo 2036","width":"50","height":"50","transform":"translate(658 718)","fill":"#fafafa"}}),_c('path',{attrs:{"id":"Icon_ionic-ios-arrow-back","data-name":"Icon ionic-ios-arrow-back","d":"M14.074,14.382l6.2-6.193a1.171,1.171,0,1,0-1.658-1.653l-7.022,7.017a1.168,1.168,0,0,0-.034,1.614l7.051,7.066a1.171,1.171,0,1,0,1.658-1.653Z","transform":"translate(668.807 758.615) rotate(-90)","fill":"#131314"}})])]),_c('span',{staticStyle:{"position":"absolute"}},[_vm._v("Derek")])])]),(_vm.bannerHome.tipo == 2)?_c('div',{staticClass:"d-flex d-md-none col-12 col-md-6 baner-large-video",on:{"click":function($event){return _vm.clicBtn('video movil')}}},[_c('video',{staticClass:"w-100",attrs:{"autoplay":"","loop":"","playsinline":"","muted":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.bannerHome.imagen_movil,"type":"video/mp4"}})])]):_c('div',{staticClass:"d-flex d-md-none col-12 col-md-6 baner-large",style:('background-image: url(' +
          _vm.bannerHome.imagen_movil +
          '); background-position: 50% top;'),on:{"click":function($event){return _vm.clicBtn('imagen movil')}}},[_c('a',{staticClass:"btn-banner-nav",staticStyle:{"position":"relative","color":"transparent"},attrs:{"href":"#nav-box"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"40","height":"40","viewBox":"0 0 50 50"}},[_c('g',{attrs:{"id":"Grupo_4776","data-name":"Grupo 4776","transform":"translate(-658 -718)"}},[_c('rect',{attrs:{"id":"Rectángulo_2036","data-name":"Rectángulo 2036","width":"50","height":"50","transform":"translate(658 718)","fill":"#fafafa"}}),_c('path',{attrs:{"id":"Icon_ionic-ios-arrow-back","data-name":"Icon ionic-ios-arrow-back","d":"M14.074,14.382l6.2-6.193a1.171,1.171,0,1,0-1.658-1.653l-7.022,7.017a1.168,1.168,0,0,0-.034,1.614l7.051,7.066a1.171,1.171,0,1,0,1.658-1.653Z","transform":"translate(668.807 758.615) rotate(-90)","fill":"#131314"}})])]),_c('span',{staticStyle:{"position":"absolute"}},[_vm._v("Derek")])])])]),(_vm.bannerHome.vitrinas?.length)?_vm._l((_vm.bannerHome.vitrinas),function(vitrina,index){return _c('carousel-products',{key:index,staticClass:"container-max-width pb-0",attrs:{"products-analytics":vitrina.productos_analytics,"products":vitrina.items,"title":vitrina.title + ' ' + vitrina.slug,"slug":vitrina.query,"position":vitrina.query}})}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }