<template>
  <div class="h-100 d-flex align-items-center">
    <!-- <div class="mt-5 pt-4 col-12 text-center" v-if="errorMagicLink">
            <h3 class="font-weight-bold mb-3">Parece que algo salio mal :(</h3>
            <p class="fs-6 mb-4">No fue posible validar la información del usuario</p>
            <a href="/" class="btn-primary py-3 fs-6" target="_self">Volver a la tienda</a>
        </div> -->
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      cartId: '',
      tokenCart: '',
      errorMagicLink: false,
    }
  },
  async mounted() {
    document.title =
      'Descubre las ultimas tendencias en moda femenina, ropa, zapatos y accesorios.'
    this.cartId = this.$route.params.cartId
    this.tokenCart = this.$route.params.token
    this.$store.state.loaderDerek = true
    await this.axios({
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/cart/tobuy/' + this.cartId + '-' + this.tokenCart,
    })
      .then(async response => {
        // this.$store.state.user.dataUser = response.data.user;
        // this.$store.state.user.directions = response.data.direcciones
        // window.localStorage.setItem('accessToken', response.data.access_token);
        // window.localStorage.setItem('tokenFavorite', response.data.tokenWhislist);
        if (window.localStorage.getItem('accessToken')) {
          await this.logout()
        }
        window.localStorage.removeItem('tokenCart')
        window.localStorage.setItem('tokenCart', response.data.tokenCart)
        await this.getProducts()
        // this.getProductsFavorites();
        if (this.$route.query.url_product) {
          this.$router.replace({
            name: 'ProductDetail',
            params: {
              product: this.$route.query.url_product.replace('product-', ''),
              vitrinaId: 'compra-page',
              vitrinaName: 'Comprar',
            },
          })
        } else {
          this.$router.replace({ name: 'MyBag' })
        }
      })
      .catch(e => {
        this.errorMagicLink = true
        this.$router.replace({ name: 'MyBag' })
        console.log(e)
      })
      .finally(() => {
        this.$store.state.loaderDerek = false
      })
  },
  methods: {
    ...mapActions('cart', ['getProducts']),
    ...mapActions('products', ['getProductsFavorites']),
    ...mapActions('user', ['logout']),
  },
}
</script>
