import Vue from 'vue'
import $store from '../../store/index'
// ---||  [ Añadir producto al carrito ]  ||--- //
export async function addToCart({ state }, payload) {
  if (payload.size != null && payload.color != null) {
    var form = new FormData()
    state.msgErrorAddProduct = ''
    form.append(
      'token',
      window.localStorage.getItem('tokenCart')
        ? window.localStorage.getItem('tokenCart')
        : '',
    )
    form.append('producto_sku', payload.sku)
    form.append('color', payload.color)
    form.append('talla_id', payload.size)
    await Vue.axios({
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/cart/add_product',
      data: form,
    })
      .then(function(response) {
        window.localStorage.setItem('tokenCart', response.data.carrito.token)
        state.dataCart = response.data.carrito
        state.productsCart = response.data.productos
        state.productStrategy = response.data.productos_estrategias
        state.productFeatured = response.data.productos_destacados.productos
        state.relatedAnalytics =
          response.data.productos_destacados.productos_analytics
        if (response.data.whislist) {
          $store.state.products.productWhislist =
            response.data.whislist.productos
        }
      })
      .catch(e => {
        if (e.response.data.message) {
          state.msgErrorAddProduct = e.response.data.message
        } else {
          let message = ''
          for (const property in e.response.data.errors) {
            message = message + e.response.data.errors[property] + ' '
            if (property == 'token') {
              window.localStorage.removeItem('tokenCart')
              state.productsCart = null
              state.productStrategy = null
            }
          }
          state.msgErrorAddProduct = message
        }
      })
      .finally(() => {
        console.log('Done')
      })
  }
}

// ---||  [ Listar productos del carrito ]  ||--- //
export async function getProducts({ state }) {
  state.loadingBag = true
  var form = new FormData()
  form.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/get_products',
    data: form,
  })
    .then(function(response) {
      state.dataCart = response.data.carrito
      state.productsCart = response.data.productos
      state.productFeatured = response.data.productos_destacados.productos
      state.relatedAnalytics =
        response.data.productos_destacados.productos_analytics
      state.productStrategy = response.data.productos_estrategias
      window.localStorage.setItem('tokenCart', response.data.carrito.token)
    })
    .catch(e => {
      let message = ''
      for (const property in e.response.data.errors) {
        message = message + e.response.data.errors[property] + ' '
        if (property == 'token') {
          window.localStorage.removeItem('tokenCart')
          state.productsCart = null
          state.productStrategy = null
        }
      }
    })
    .finally(() => {
      state.loadingBag = false
      console.log('getProduct Cart Done')
    })
}

// ---||  [ Eliminar producto del carrito ]  ||--- //
export async function deleteCartProduct({ state }, payload) {
  var form = new FormData()
  state.msgErrorAddProduct = ''
  form.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  form.append('id_carrito_producto', payload.id_carrito_producto)
  form.append('tipo', payload.tipo)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/delete_product',
    data: form,
  })
    .then(function(response) {
      if (response.data.productos) {
        state.dataCart = response.data.carrito
        state.productsCart = response.data.productos
        state.productFeatured = response.data.productos_destacados.productos
        state.relatedAnalytics =
          response.data.productos_destacados.productos_analytics
        state.productStrategy = response.data.productos_estrategias
      } else {
        window.localStorage.removeItem('tokenCart')
        state.dataCart = null
        state.productsCart = null
        state.productFeatured = null
        state.relatedAnalytics = null
        state.productStrategy = null
      }
    })
    .catch(e => {
      if (e.response.data.message) {
        state.msgErrorAddProduct = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
          if (property == 'token') {
            window.localStorage.removeItem('tokenCart')
            state.productsCart = null
            state.productStrategy = null
          }
        }
        state.msgErrorAddProduct = message
      }
    })
    .finally(() => {
      console.log('Delete Cart Done')
    })
}
// ---||  [ Actualizar correo de carrito ]  ||--- //
export async function updateEmailCart({ state }, payload) {
  var form = new FormData()
  form.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  form.append('cliente_correo', payload.correo)
  form.append('step', 1)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/updateInfoCart',
    data: form,
  })
    .then(function(response) {
      window.localStorage.setItem('tokenCart', response.data.carrito.token)
      state.dataCart = response.data.carrito
      state.productsCart = response.data.productos
    })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
      }
      state.msgErrorCheckout = message
      setTimeout(() => {
        state.msgErrorCheckout = ''
      }, 10000)
    })
    .finally(() => {
      console.log('Update Email Done')
    })
}
// ---||  [ Actualizar información de carrito ]  ||--- //
export async function updateInfoCart({ state }, payment) {
  var form = new FormData()
  form.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  form.append('cliente_correo', state.dataCart.cliente_correo)
  form.append('cliente_celular', state.dataCart.cliente_celular)
  form.append('cliente_nombre', state.dataCart.cliente_nombre)
  form.append('cliente_apellidos', state.dataCart.cliente_apellidos)
  form.append('tipodoc', state.dataCart.tipodoc)
  form.append('cliente_documento', state.dataCart.cliente_documento)
  form.append('cliente_direccion', state.dataCart.cliente_direccion)
  form.append('cliente_departamento', state.dataCart.cliente_departamento)
  form.append('cliente_ciudad', state.dataCart.cliente_ciudad)
  form.append('cliente_barrio', state.dataCart.cliente_barrio)
  form.append('direccion_id', state.dataCart?.idDir)
  // form.append("destinatario", state.dataCart?.destinatario);
  form.append('observaciones', state.dataCart?.observaciones)
  form.append('step', payment.step)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/updateInfoCart',
    data: form,
  })
    .then(function(response) {
      window.localStorage.setItem('tokenCart', response.data.carrito.token)
      state.dataCart = response.data.carrito
      state.productsCart = response.data.productos
    })
    .catch(e => {
      console.log(e)
    })
    .finally(() => {
      console.log('updateInfoData Done')
    })
}
// ---||  [ Pago contra entrega (CheckOut) ]  ||--- //
export async function PaymentUponDelibery({ state }, payload) {
  var form = new FormData()
  form.append('token', window.localStorage.getItem('tokenCart'))
  form.append('pedido_fe_dian', payload.esFE)
  state.msgErrorCheckout = ''
  state.msgModalExcepcion406 = ''
  state.btnModalExcepcion406 = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/orders/payment/orderAgainstDelivery',
    data: form,
  })
    .then(function(response) {
      state.dataSummaryPayment = response.data.response
      window.localStorage.removeItem('tokenCart')
    })
    .catch(e => {
      if (e.response.status == 423) {
        // $store.state.dataModal423.descripcion =
        //   e.response.data.errors.carrito[0]
        // $store.state.dataModal423.routeName =
        //   e.response.data.redireccionar_catalogo &&
        //   e.response.data.redireccionar_catalogo == 1
        //     ? 'Store'
        //     : 'PaymentEmail'
        $store.state.showModal423 = true
      } else if (e.response.status == 406) {
        state.msgModalExcepcion406 = e.response.data.errors.bono_regalo[0]
        state.btnModalExcepcion406 = 'controEntrega'
      } else {
        let message = ''
        if (e.response.data.message) {
          message = e.response.data.message
        } else {
          for (const property in e.response.data.errors) {
            message = message + e.response.data.errors[property] + ' '
          }
        }
        state.msgErrorCheckout = message
      }
    })
    .finally(() => {
      console.log('Done')
    })
}
// ---||  [ Añadir Bono regalo (CheckOut) ]  ||--- //
export async function AddBonoGift({ state }, payload) {
  $store.state.loaderDerek = true
  var form = new FormData()
  form.append('codigo', payload.codigo)
  form.append('token', window.localStorage.getItem('tokenCart'))
  form.append('pedido_fe_dian', payload.esFE)
  state.msgErrorCheckout = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/apply_giftcard',
    data: form,
  })
    .then(function(response) {
      window.localStorage.setItem('tokenCart', response.data.carrito.token)
      state.dataCart = response.data.carrito
      state.productsCart = response.data.productos
    })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
      }
      state.msgErrorCheckout = message
      $store.state.loaderDerek = false
    })
    .finally(() => {
      $store.state.loaderDerek = false
      console.log('Apply Bono')
    })
}
// ---||  [ Remover Bono regalo (CheckOut) ]  ||--- //
export async function removeBono({ state }) {
  let data = new FormData()
  $store.state.loaderDerek = true
  data.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/delete_giftcard',
    data: data,
  })
    .then(response => {
      window.localStorage.setItem('tokenCart', response.data.carrito.token)
      state.dataCart = response.data.carrito
      state.productsCart = response.data.productos
      $store.state.loaderDerek = false
    })
    .catch(e => {
      let message = ''
      $store.state.loaderDerek = false
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.errors) {
          message = message + e.response.errors[property] + ' '
        }
      }
    })
}

// ---||  [ Pagar con Bono Regalo (CheckOut) ]  ||--- //
export async function payWhitGiftCard({ state }) {
  var form = new FormData()
  form.append('token', window.localStorage.getItem('tokenCart'))
  state.msgErrorCheckout = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/orders/payment/giftcard',
    data: form,
  })
    .then(function(response) {
      state.dataSummaryPayment = response.data.response
      window.localStorage.removeItem('tokenCart')
    })
    .catch(e => {
      if (e.response.status == 423) {
        // $store.state.dataModal423.descripcion =
        //   e.response.data.errors.carrito[0]
        // $store.state.dataModal423.routeName =
        //   e.response.data.redireccionar_catalogo &&
        //   e.response.data.redireccionar_catalogo == 1
        //     ? 'Store'
        //     : 'PaymentEmail'
        $store.state.showModal423 = true
      } else if (e.response.status == 406) {
        state.msgModalExcepcion406 = e.response.data.errors.bono_regalo[0]
        state.btnModalExcepcion406 = 'bonoRegalo'
      } else {
        let message = ''
        if (e.response.data.message) {
          message = e.response.data.message
        } else {
          for (const property in e.response.data.errors) {
            message = message + e.response.data.errors[property] + ' '
          }
        }
        state.msgErrorCheckout = message
      }
    })
    .finally(() => {
      console.log('Done')
    })
}
// ---||  [ Pago Efecty o Baloto (CheckOut) ]  ||--- //
export async function paymentEfectyBaloto({ state }, payload) {
  state.errorPaymentBillBalotoEfecty = ''
  state.msgModalExcepcion406 = ''
  state.btnModalExcepcion406 = ''
  var form = new FormData()
  form.append('token', window.localStorage.getItem('tokenCart'))
  form.append('ticketId', payload.typePago)
  form.append('pedido_fe_dian', payload.esFE)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/payment/payu/chargeEfectivo',
    data: form,
  })
    .then(function(response) {
      state.dataSummaryPayment = response.data.response
      window.localStorage.removeItem('tokenCart')
      state.errorPaymentBillBalotoEfecty = ''
      window.open(
        response.data.response.pedido.pedido.transaccion
          .transaccion_urlefectybaloto,
        '_blank',
      )
    })
    .catch(e => {
      if (e.response.status == 423) {
        // $store.state.dataModal423.descripcion =
        //   e.response.data.errors.carrito[0]
        // $store.state.dataModal423.routeName =
        //   e.response.data.redireccionar_catalogo &&
        //   e.response.data.redireccionar_catalogo == 1
        //     ? 'Store'
        //     : 'PaymentEmail'
        $store.state.showModal423 = true
      } else if (e.response.status == 406) {
        state.msgModalExcepcion406 = e.response.data.errors.bono_regalo[0]
        state.btnModalExcepcion406 = 'efectyBaloto'
      } else {
        let message = ''
        if (e.response.data.message) {
          message = e.response.data.message
        } else {
          for (const property in e.response.data.errors) {
            message = message + e.response.data.errors[property] + ' '
          }
        }
        state.errorPaymentBillBalotoEfecty = message
      }
    })
    .finally(() => {
      console.log('Payment Efecty-Via Done')
    })
}
// ---||  [ Pagar PSE (CheckOut) ]  ||--- //
export async function payPSE({ state }, payload) {
  var form = new FormData()
  state.msgModalExcepcion406 = ''
  state.btnModalExcepcion406 = ''
  form.append('token', window.localStorage.getItem('tokenCart'))
  form.append('docFinancialInstitution', payload.codPSE)
  form.append('pedido_fe_dian', payload.esFE)
  // form.append("nombreTitular", payload.nombre);
  // form.append("tipoCliente", payload.tipoCliente);
  // form.append("PSETipoDoc", payload.tipoDoc);
  // form.append("PSEDocNumero", payload.doc);
  state.errorPSE = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/payment/payu/chargePSE',
    data: form,
  })
    .then(response => {
      // localStorage.removeItem('tokenCart');
      state.urlPayPSE = response.data.response.url
      // state.dataSummaryPayment = response.data.response;
    })
    .catch(e => {
      if (e.response.status == 423) {
        // $store.state.dataModal423.descripcion =
        //   e.response.data.errors.carrito[0]
        // $store.state.dataModal423.routeName =
        //   e.response.data.redireccionar_catalogo &&
        //   e.response.data.redireccionar_catalogo == 1
        //     ? 'Store'
        //     : 'PaymentEmail'
        $store.state.showModal423 = true
      } else if (e.response.status == 406) {
        state.msgModalExcepcion406 = e.response.data.errors.bono_regalo[0]
        state.btnModalExcepcion406 = 'pse'
      } else {
        // let message = ''
        // if (e.response.data.message) {
        //     message = e.response.data.message;
        // } else {
        //     for (const property in e.response.data.errors) {
        //         message = message + e.response.data.errors[property] + " "
        //     }
        // }
        state.errorPSE = e.response.data.response.msg
      }
    })
    .finally(() => {
      console.log('Done')
    })
}
// ---||  [ Pagar Addi (CheckOut) ]  ||--- //
export async function payAddi({ state }) {
  var form = new FormData()
  state.msgModalExcepcion406 = ''
  state.btnModalExcepcion406 = ''
  form.append('token', window.localStorage.getItem('tokenCart'))
  state.errorAddi = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/payment/addi/compra',
    data: form,
  })
    .then(response => {
      state.urlPayAddi = response.data
    })
    .catch(e => {
      if (e.response.status == 423) {
        $store.state.showModal423 = true
      } else if (e.response.status == 406) {
        state.msgModalExcepcion406 = e.response.data.errors.bono_regalo[0]
        state.btnModalExcepcion406 = 'addi'
      } else {
        state.errorAddi = e.response.data.response.msg
      }
    })
    .finally(() => {
      console.log('Done')
    })
}
// ---||  [ Pagar con tarjeta de credito (CheckOut) ]  ||--- //
export async function paymentCreditcard({ state }, payload) {
  var form = new FormData()
  state.msgModalExcepcion406 = ''
  state.btnModalExcepcion406 = ''
  form.append('token', window.localStorage.getItem('tokenCart'))
  form.append('paymentMethodID', payload.paymentMethodID)
  form.append('securityCode', payload.securityCode)
  form.append('cardExpirationYear', payload.cardExpirationYear)
  form.append('cardholderName', payload.cardholderName)
  form.append('docNumber', payload.docNumber)
  form.append('cardNumber', payload.cardNumber)
  form.append('cardExpirationMonth', payload.cardExpirationMonth)
  form.append('docType', payload.docType)
  form.append('installments', payload.installments)
  form.append('pedido_fe_dian', payload.esFE)
  form.append('token_card', payload.payloadMercadoPago.token)
  form.append('paymentMethodID', payload.payloadMercadoPago.paymentMethodId)
  form.append('issuerID', payload.payloadMercadoPago.issuerId)
  state.errorCreditCard = ''
  await Vue.axios({
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      'X-meli-session-id': window.MP_DEVICE_SESSION_ID,
    },
    url: '/payment/payu/credit_card_charge',
    data: form,
  })
    .then(response => {
      state.dataSummaryPayment = response.data.response
      window.localStorage.removeItem('tokenCart')
      state.errorPaymentBillBalotoEfecty = false
    })
    .catch(e => {
      if (e.response.status == 423) {
        $store.state.showModal423 = true
      } else if (e.response.status == 406) {
        state.msgModalExcepcion406 = e.response.data.errors.bono_regalo[0]
        state.btnModalExcepcion406 = 'creditCard'
      } else {
        state.errorCreditCard = e.response.data.response.msg
      }
    })
    .finally(() => {
      console.log('Done')
    })
}
////////////////////////////////////////////////////
// ---||  [ Pasos del cupo (CheckOut) ]  ||--- /
////////////////////////////////////////////////////

// ---||  [ Validar Documento cupo (CheckOut) ]  ||--- //
export async function validateDocCupo({ state }, payload) {
  var form = new FormData()
  form.append('token', window.localStorage.getItem('tokenCart'))
  form.append('documento', payload.doc)
  state.errorCupo = false
  state.msgerrorCupo = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/payment/cupopersonal/validarDocumentoClienteCupo',
    data: form,
  })
    .then(response => {
      state.dataCupo = response.data.response.data_form
    })
    .catch(e => {
      state.errorCupo = true
      if (e.response.data.message) {
        state.msgerrorCupo = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgerrorCupo = message
      }
    })
    .finally(() => {
      console.log('validateDocCupo Done')
    })
}
export async function resendCodCupoCall({ state }, payload) {
  var form = new FormData()
  form.append('token', window.localStorage.getItem('tokenCart'))
  form.append('documento', payload.doc)
  state.errorCupo = false
  state.msgerrorCupo = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/comunications/transactional/reenviarTokenVoz',
    data: form,
  })
    .then(() => {
      console.log('')
    })
    .catch(e => {
      state.errorCupo = true
      if (e.response.data.message) {
        state.msgerrorCupo = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgerrorCupo = message
      }
    })
    .finally(() => {
      console.log('resendCodCupoCall Done')
    })
}
export async function resendCodCupoWPP({ state }, payload) {
  var form = new FormData()
  form.append('token', window.localStorage.getItem('tokenCart'))
  form.append('documento', payload.doc)
  state.errorCupo = false
  state.msgerrorCupo = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/comunications/transactional/reenviarTokenWhatsApp',
    data: form,
  })
    .then(() => {
      console.log('')
    })
    .catch(e => {
      state.errorCupo = true
      if (e.response.data.message) {
        state.msgerrorCupo = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgerrorCupo = message
      }
    })
    .finally(() => {
      console.log('resendCodCupoCall Done')
    })
}
export async function resendCodCupoSMS({ state }, payload) {
  var form = new FormData()
  form.append('token', window.localStorage.getItem('tokenCart'))
  form.append('documento', payload.doc)
  state.errorCupo = false
  state.msgerrorCupo = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/comunications/transactional/reenviarTokenSMS',
    data: form,
  })
    .then(() => {
      console.log('')
    })
    .catch(e => {
      state.errorCupo = true
      if (e.response.data.message) {
        state.msgerrorCupo = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgerrorCupo = message
      }
    })
    .finally(() => {
      console.log('resendCodCupoCall Done')
    })
}
export async function validateTokenCupo({ state }, payload) {
  var form = new FormData()
  form.append('token', window.localStorage.getItem('tokenCart'))
  form.append('documento', payload.doc)
  form.append('cupoCodigoSMS', payload.cupoCodigoSMS)
  state.errorCupo = false
  state.msgerrorCupo = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/payment/cupopersonal/validateToken',
    data: form,
  })
    .then(response => {
      state.dataSummaryPayment = response.data.response
      window.localStorage.removeItem('tokenCart')
      state.errorPaymentBillBalotoEfecty = false
    })
    .catch(e => {
      state.errorCupo = true
      if (e.response.data.message) {
        state.msgerrorCupo = e.response.data.message
      } else {
        let message = ''
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
        state.msgerrorCupo = message
      }
    })
    .finally(() => {
      console.log('resendCodCupoCall Done')
    })
}
// ---||  [ Agregar cupón de carrito ]  ||--- //
export async function applyCupon({ state }, payload) {
  var form = new FormData()
  form.append('cupon_codigo', payload.discountCode)
  form.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  state.msgErrorCupon = ''
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/applyCoupon',
    data: form,
  })
    .then(function(response) {
      window.localStorage.setItem('tokenCart', response.data.carrito.token)
      state.dataCart = response.data.carrito
      state.productsCart = response.data.productos
      state.productStrategy = response.data.productos_estrategias
      state.dataCart = response.data.carrito
    })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.data.errors) {
          message = message + e.response.data.errors[property] + ' '
        }
      }
      state.msgErrorCupon = message
    })
    .finally(() => {
      console.log('getProduct Cart Done')
    })
}
export async function removeCupon({ state }) {
  let data = new FormData()
  data.append(
    'token',
    window.localStorage.getItem('tokenCart')
      ? window.localStorage.getItem('tokenCart')
      : '',
  )
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/removeCoupon',
    data: data,
  })
    .then(response => {
      window.localStorage.setItem('tokenCart', response.data.carrito.token)
      state.dataCart = response.data.carrito
      state.productsCart = response.data.productos
      state.productStrategy = response.data.productos_estrategias
      state.dataCart = response.data.carrito
    })
    .catch(e => {
      let message = ''
      if (e.response.data.message) {
        message = e.response.data.message
      } else {
        for (const property in e.response.errors) {
          message = message + e.response.errors[property] + ' '
        }
      }
      console.log(message)
    })
}
// ---||  [ Agregar estrategia al carrito ]  ||--- //
export async function addStrategyToCart({ state }, payload) {
  if (payload.size != null && payload.color != null) {
    var form = new FormData()
    form.append(
      'token',
      window.localStorage.getItem('tokenCart')
        ? window.localStorage.getItem('tokenCart')
        : '',
    )
    form.append('producto_sku', payload.sku)
    form.append('color', payload.color)
    form.append('talla_id', payload.size)
    form.append('estrategia_id', payload.estrategia_id)
    await Vue.axios({
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/cart/add_product_estrategia',
      data: form,
    })
      .then(function(response) {
        window.localStorage.setItem('tokenCart', response.data.carrito.token)
        state.dataCart = response.data.carrito
        state.productsCart = response.data.productos
        state.productStrategy = response.data.productos_estrategias
      })
      .catch(e => {
        console.log(e)
        if (e.response.data.message) {
          state.msgErrorAddProduct = e.response.data.message
        } else {
          let message = ''
          for (const property in e.response.data.errors) {
            message = message + e.response.data.errors[property] + ' '
          }
          state.msgErrorAddProduct = message
        }
      })
      .finally(() => {
        console.log('Done')
      })
  }
}
////////////////////////////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////

// ---||  [ Registrar encuesta (CheckOut) ]  ||--- //
export async function RegisterPollCheckout({ state }, payload) {
  var form = new FormData()
  form.append('pedido_id', payload.pedido_id)
  form.append('puntaje', payload.puntaje)
  form.append('mensaje', payload.mensaje)
  state.errorSurvey = false
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/orders/order/survey/send',
    data: form,
  })
    .then(() => {
      console.log(state != '')
    })
    .catch(e => {
      console.log(e)
      state.errorSurvey = true
    })
    .finally(() => {
      console.log('Done')
    })
}

// ---||  [ Servicio productos agotados ]  ||--- //

export async function registerProductsSoldout({ state }, payload) {
  let form = new FormData()
  const token = window.localStorage.getItem('tokenCart')
  console.log(payload)
  form.append('token', token)
  form.append('cliente_correo', payload.correo)
  await Vue.axios({
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    url: '/cart/validate_products',
    data: form,
  })
    .then(() => {
      location.reload()
      console.log(state)
    })
    .catch(function(error) {
      var message = ''
      var obj = error.response.data.errors
      for (const prop in obj) {
        message += `${obj[prop][0]} \n`
      }
      console.log('Error: ', message)
      return false
    })
}
