<template>
  <div
    :id="'vitrina-banner-' + slug"
    class="col-12 d-block d-md-flex mt-5 pb-5 pb-md-0"
    :style="'background-color:' + bgVitrina"
  >
    <div class="container-max-width d-flex flex-wrap w-100">
      <!-- <div class="col-12 col-md-5 baner-carousel" @click="$router.push({ name: 'TiendaCategory', params: { category: slug },})" :style="'background-image: url(' + imgURL + ');'"/> -->
      <div class="col-12">
        <div class="carousel-products-banner">
          <div>
            <h2 class="title-carousel">{{ title }} /</h2>
          </div>
          <carousel
            :id="'carousel-banner-' + slug"
            :key="innerWidth"
            class="col-12 px-0 mx-0 position-relative"
            :dots="true"
            :nav="false"
            :dots-each="true"
            :responsive="{
              0: { items: 1, nav: false, stagePadding: 30 },
              704: { items: 2, nav: false, stagePadding: 60 },
              1030: { items: itemsXfila, nav: false, stagePadding: 80 },
            }"
          >
            <template slot="prev"
              ><span
                class="prev nav-button d-none d-md-block"
                @click="
                  positionDot <= 0
                    ? (positionDot = products.length - 1)
                    : (positionDot -= 1)
                "
              >
                <img
                  src="@/assets/icons/left-carousel.svg"
                  alt="left arrow carousel"
                /> </span
            ></template>
            <template slot="next"
              ><span
                class="next nav-button d-none d-md-block"
                @click="
                  positionDot >= products.length - 1
                    ? (positionDot = 0)
                    : (positionDot += 1)
                "
              >
                <img
                  src="@/assets/icons/right-carousel.svg"
                  alt="right arrow carousel"
                /> </span
            ></template>
            <router-link
              class="d-flex w-100 pe-3"
              :to="{ name: 'TiendaCategory', params: { category: slug } }"
            >
              <div
                class="bg-card-img"
                :style="'background-image: url(' + imgURL + ');'"
              ></div>
            </router-link>
            <div
              v-for="(product, index) in products"
              :key="index"
              class="item-product-carousel"
              :to="{ name: '' }"
              @click="clicProduct(productsAnalytics, product.producto_sku)"
            >
              <card-product-hover
                id="box-sizes-carousel-banner"
                :product="product"
                :vitrina-id="position"
                :vitrina-name="title"
              />
            </div>
            <router-link
              class="w-100"
              :to="{ name: 'TiendaCategory', params: { category: slug } }"
            >
              <div class="last-card-carousel">
                <p>
                  Descubre aquí más <br />
                  productos similares
                </p>
              </div>
            </router-link>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import carousel from 'v-owl-carousel'
import CardProductHover from '@/components/Templates/CardProductHover.vue'
import { GA4ViewItemList } from '@/GA4/events'

export default {
  components: { carousel, CardProductHover },
  props: {
    products: {
      type: Array,
      required: false,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    imgURL: {
      type: String,
      required: false,
      default: '',
    },
    bgVitrina: {
      type: String,
      required: false,
      default: '',
    },
    slug: {
      type: String,
      required: false,
      default: '',
    },
    productsAnalytics: {
      type: Array,
      required: false,
      default: () => [],
    },
    itemsXfila: {
      type: Number,
      required: false,
      default: 3,
    },
    position: {
      type: String,
      required: false,
      default: 'carousel',
    },
  },
  data() {
    return {
      positionDot: 0,
      showBannerVitrina: false,
    }
  },
  computed: {
    ...mapState(['innerWidth']),
  },
  watch: {
    innerWidth() {
      this.validateDotsCarousel()
    },
  },
  async mounted() {
    await this.validateDotsCarousel()
    window.addEventListener('scroll', () => {
      if (document.getElementById('vitrina-banner-' + this.slug)) {
        let vitrina = document.getElementById('vitrina-banner-' + this.slug)
        if (
          vitrina &&
          !this.showBannerVitrina &&
          vitrina.getBoundingClientRect().top -
            (window.innerHeight - vitrina.offsetHeight / 2) <=
            0
        ) {
          GA4ViewItemList(this.products, this.position, this.title)
          this.showBannerVitrina = true
          window.removeEventListener('scroll', () => {})
        }
      }
    })
  },
  methods: {
    async validateDotsCarousel() {
      setTimeout(() => {
        let element = document
          .getElementById('carousel-banner-' + this.slug)
          .getElementsByClassName('owl-dot')
        if (element) {
          for (let index = 0; index < element.length; index++) {
            element[index].ariaLabel = 'Dot carousel'
            element[index].style.width =
              100 /
                (window.innerWidth >= 1048
                  ? parseInt(this.products.length / 2)
                  : this.products.length) +
              '%'
          }
        }
      }, 100)
    },
    clicProduct(dataAnalitycs, skuSearch) {
      let productAnalitycs = dataAnalitycs?.find(e => e.id == skuSearch)
      if (productAnalitycs) {
        // window.alert('CarouselProductsBanner.vue')
        // DK-ProductClick
        // window.dataLayer.push({
        //   event: 'DK-ProductClick',
        //   name: productAnalitycs.name,
        //   id: productAnalitycs.id,
        //   price: productAnalitycs.price,
        //   brand: productAnalitycs.brand,
        //   category: productAnalitycs.category,
        //   variant: productAnalitycs.variant,
        //   position: productAnalitycs.position,
        // })
        // this.$gtag.event('productClick', {
        //   ecommerce: {
        //     click: {
        //       actionField: { list: productAnalitycs.category },
        //       products: [
        //         {
        //           name: productAnalitycs.name,
        //           id: productAnalitycs.id,
        //           price: productAnalitycs.price,
        //           brand: productAnalitycs.brand,
        //           category: productAnalitycs.category,
        //           variant: productAnalitycs.variant,
        //           position: productAnalitycs.position,
        //         },
        //       ],
        //     },
        //   },
        // })
      }
    },
  },
}
</script>
