<template>
  <div>
    <div class="d-block d-md-flex">
      <div class="col-12 col-md-6 content-baner">
        <p
          v-if="bannerHome.subtitulo"
          :style="bannerHome.style_subtitulo"
          v-html="bannerHome.subtitulo"
        ></p>
        <h1
          v-if="bannerHome.titulo"
          class="text-baner"
          :style="bannerHome.style_titulo"
          v-html="bannerHome.titulo"
        ></h1>
        <p
          v-if="bannerHome.descripcion"
          :style="bannerHome.style_descripcion"
          v-html="bannerHome.descripcion"
        ></p>
        <button
          class="btn-primary"
          style="font-size: 1.18rem;"
          :style="
            'color: ' +
              bannerHome.boton.color_texto +
              '!important' +
              '; background-color: ' +
              bannerHome.boton.color_fondo +
              '!important'
          "
          @click="clicBtn(bannerHome.boton.texto)"
        >
          {{ bannerHome.boton.texto }}
        </button>
      </div>
      <div
        v-if="bannerHome.tipo == 2"
        class="d-none d-md-flex col-12 col-md-6 baner-large-video"
        @click="clicBtn('video ecritorio')"
      >
        <video
          autoplay
          loop
          playsinline
          muted
          class="h-100"
          :src="bannerHome.imagen_desktop"
        ></video>
      </div>
      <div
        v-else
        class="d-none d-md-flex col-12 col-md-6 baner-large"
        :style="
          'background-image: url(' +
            bannerHome.imagen_desktop +
            '); max-height: 972px; background-position: 50% top; cursor: pointer;'
        "
        @click="clicBtn('imagen escritorio')"
      >
        <a
          href="#nav-box"
          class="btn-banner-nav"
          style="position: relative; color: transparent;"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 50 50"
          >
            <g
              id="Grupo_4776"
              data-name="Grupo 4776"
              transform="translate(-658 -718)"
            >
              <rect
                id="Rectángulo_2036"
                data-name="Rectángulo 2036"
                width="50"
                height="50"
                transform="translate(658 718)"
                fill="#fafafa"
              />
              <path
                id="Icon_ionic-ios-arrow-back"
                data-name="Icon ionic-ios-arrow-back"
                d="M14.074,14.382l6.2-6.193a1.171,1.171,0,1,0-1.658-1.653l-7.022,7.017a1.168,1.168,0,0,0-.034,1.614l7.051,7.066a1.171,1.171,0,1,0,1.658-1.653Z"
                transform="translate(668.807 758.615) rotate(-90)"
                fill="#131314"
              />
            </g>
          </svg>
          <span style="position: absolute;">Derek</span>
        </a>
      </div>
      <div
        v-if="bannerHome.tipo == 2"
        class="d-flex d-md-none col-12 col-md-6 baner-large-video"
        @click="clicBtn('video movil')"
      >
        <video autoplay loop playsinline muted class="w-100">
          <source :src="bannerHome.imagen_movil" type="video/mp4" />
        </video>
      </div>
      <div
        v-else
        class="d-flex d-md-none col-12 col-md-6 baner-large"
        :style="
          'background-image: url(' +
            bannerHome.imagen_movil +
            '); background-position: 50% top;'
        "
        @click="clicBtn('imagen movil')"
      >
        <a
          href="#nav-box"
          class="btn-banner-nav"
          style="position: relative; color: transparent;"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 50 50"
          >
            <g
              id="Grupo_4776"
              data-name="Grupo 4776"
              transform="translate(-658 -718)"
            >
              <rect
                id="Rectángulo_2036"
                data-name="Rectángulo 2036"
                width="50"
                height="50"
                transform="translate(658 718)"
                fill="#fafafa"
              />
              <path
                id="Icon_ionic-ios-arrow-back"
                data-name="Icon ionic-ios-arrow-back"
                d="M14.074,14.382l6.2-6.193a1.171,1.171,0,1,0-1.658-1.653l-7.022,7.017a1.168,1.168,0,0,0-.034,1.614l7.051,7.066a1.171,1.171,0,1,0,1.658-1.653Z"
                transform="translate(668.807 758.615) rotate(-90)"
                fill="#131314"
              />
            </g>
          </svg>
          <span style="position: absolute;">Derek</span>
        </a>
      </div>
    </div>
    <template v-if="bannerHome.vitrinas?.length">
      <carousel-products
        v-for="(vitrina, index) in bannerHome.vitrinas"
        :key="index"
        class="container-max-width pb-0"
        :products-analytics="vitrina.productos_analytics"
        :products="vitrina.items"
        :title="vitrina.title + ' ' + vitrina.slug"
        :slug="vitrina.query"
        :position="vitrina.query"
      />
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { GA4ViewPromotion, GA4SelectPromotion } from '@/GA4/events'
import CarouselProducts from '@/components/Templates/CarouselProducts.vue'
export default {
  components: { CarouselProducts },
  computed: {
    ...mapState('layouts', ['bannerHome']),
  },
  mounted() {
    GA4ViewPromotion({
      nameCreative: `${this.bannerHome.titulo}`,
      slot: `banner-home-${this.bannerHome.id}`,
      id: `banner-home-page-${this.bannerHome.id}`,
      name: this.bannerHome.nombre_promocion_ga,
      items: [],
    })
  },
  methods: {
    clicBtn(textClic) {
      GA4SelectPromotion({
        nameCreative: `${textClic}`,
        slot: `banner-home-${this.bannerHome.id}`,
        id: `${this.bannerHome.id}`,
        name: this.bannerHome.nombre_promocion_ga,
        items: [],
      })
      this.$router.push({
        path: this.bannerHome.boton.url
          ? this.bannerHome.boton.url
          : '/novedades',
      })
    },
  },
}
</script>
