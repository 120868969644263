<template>
  <div>
    <div
      v-if="dataCart.proveedor_pasarela == 'mercadopago'"
      class="container-form"
    >
      <form id="form-checkout">
        <label>Número de tarjeta</label>
        <div id="form-checkout__cardNumber" class="input-quac_tj" />
        <div class="d-flex flex-wrap">
          <div class="col-6 pe-1 ps-0">
            <label>Fecha de vencimiento</label>
            <div id="form-checkout__expirationDate" class="input-quac_tj" />
          </div>
          <div class="col-6 ps-1 pe-0">
            <label>CVV</label>
            <div id="form-checkout__securityCode" class="input-quac_tj" />
          </div>
        </div>
        <select id="form-checkout__installments" class="input-quac_tj mb-1">
        </select>
        <div class="col-12 col-md-auto popover-card-payment my-3">
          <b-link id="link-popover">
            <img class="pe-3" src="@/assets/icons/credit-card.svg" />
            ¿Dónde encuentro el código CVV?
          </b-link>
          <b-popover placement="bottom" target="link-popover" triggers="hover">
            <h5 class="font-weight-bold">Código de seguridad CVV</h5>
            <p class="fs-6">
              Los últimos tres (3) o cuatro (4) dígitos del número impreso al
              respaldo de tu tarjeta corresponden al código de seguridad (CVV) o
              CVV2. Si tienes una tarjeta de crédito American Express, el código
              lo encuentra en la parte delantera.
            </p>
          </b-popover>
        </div>
        <select id="form-checkout__issuer" class="input-quac_tj"></select>
        <label>Datos del pagador</label>
        <div class="d-flex flex-wrap">
          <div class="col-6 pe-1 ps-0">
            <select
              id="form-checkout__identificationType"
              class="input-quac_tj"
            >
            </select>
          </div>
          <div class="col-6 ps-1 pe-0">
            <input
              id="form-checkout__identificationNumber"
              type="text"
              class="input-quac_tj"
            />
          </div>
        </div>
        <label>Nombre y apellido como aparece en la tarjeta</label>
        <input
          id="form-checkout__cardholderName"
          type="text"
          class="input-quac_tj"
        />
        <input
          id="form-checkout__cardholderEmail"
          type="email"
          class="input-quac_tj d-none"
        />
        <button
          id="form-checkout__submit"
          type="submit"
          class="btn-primary btn-finalize-payment w-100 my-3"
        >
          Finalizar compra
        </button>
        <div v-if="errorCreditCard" class="my-3 text-center">
          <span class="msgError fs-6" v-html="errorCreditCard"></span>
        </div>
      </form>
    </div>
    <div v-else class="content-payment-credit-card">
      <div class="form-datos payment-input-methods">
        <div class="payment-input-100 position-relative">
          <div class="position-relative w-100">
            <input
              id="input-credit-card"
              v-model="nCreditCard"
              class="payment-input w-100"
              type="cc-number"
              placeholder="Número de tarjeta"
              @blur="validateCreditCard()"
            />
            <img
              v-if="typeCard == 'visa'"
              class="credit-card"
              src="@/assets/icons/visa.svg"
              alt=""
            />
            <img
              v-if="typeCard == 'mastercard'"
              class="credit-card"
              src="@/assets/icons/mastercard.svg"
              alt=""
            />
            <img
              v-if="typeCard == 'diners'"
              class="credit-card"
              src="@/assets/icons/diners.svg"
              alt=""
            />
            <img
              v-if="typeCard == 'codensa'"
              class="credit-card"
              src="@/assets/icons/codensa.svg"
              alt=""
            />
            <img
              v-if="typeCard == 'amex'"
              class="credit-card"
              src="@/assets/icons/americanexpress.svg"
              alt=""
            />
          </div>
          <span v-if="validateNCreditCard" class="msgError"
            >Este campo es obligatorio</span
          >
          <span v-if="nCreditCard" class="newlatter-error-input"
            >Número de tarjeta</span
          >
        </div>
        <div class="position-relative payment-input-left">
          <select v-model="month" class="payment-select w-100">
            <option value="">Mes vencimiento</option>
            <option value="01"> 01 </option>
            <option value="02"> 02 </option>
            <option value="03"> 03 </option>
            <option value="04"> 04 </option>
            <option value="05"> 05 </option>
            <option value="06"> 06 </option>
            <option value="07"> 07 </option>
            <option value="08"> 08 </option>
            <option value="09"> 09 </option>
            <option value="10"> 10 </option>
            <option value="11"> 11 </option>
            <option value="12"> 12 </option>
          </select>
          <span v-if="validateMonth" class="msgError"
            >Este campo es obligatorio</span
          >
          <span v-if="month" class="newlatter-error-input"
            >Mes vencimiento</span
          >
        </div>
        <div class="position-relative payment-input-right">
          <select v-model="year" class="payment-select w-100">
            <option value="">Año vencimiento</option>
            <option :value="new Date().getFullYear()">{{
              new Date()
                .getFullYear()
                .toString()
                .slice(2, 4)
            }}</option>
            <option :value="new Date().getFullYear() + 1">{{
              (new Date().getFullYear() + 1).toString().slice(2, 4)
            }}</option>
            <option :value="new Date().getFullYear() + 2">{{
              (new Date().getFullYear() + 2).toString().slice(2, 4)
            }}</option>
            <option :value="new Date().getFullYear() + 3">{{
              (new Date().getFullYear() + 3).toString().slice(2, 4)
            }}</option>
            <option :value="new Date().getFullYear() + 4">{{
              (new Date().getFullYear() + 4).toString().slice(2, 4)
            }}</option>
            <option :value="new Date().getFullYear() + 5">{{
              (new Date().getFullYear() + 5).toString().slice(2, 4)
            }}</option>
            <option :value="new Date().getFullYear() + 6">{{
              (new Date().getFullYear() + 6).toString().slice(2, 4)
            }}</option>
            <option :value="new Date().getFullYear() + 7">{{
              (new Date().getFullYear() + 7).toString().slice(2, 4)
            }}</option>
            <option :value="new Date().getFullYear() + 8">{{
              (new Date().getFullYear() + 8).toString().slice(2, 4)
            }}</option>
            <option :value="new Date().getFullYear() + 9">{{
              (new Date().getFullYear() + 9).toString().slice(2, 4)
            }}</option>
            <option :value="new Date().getFullYear() + 10">{{
              (new Date().getFullYear() + 10).toString().slice(2, 4)
            }}</option>
            <option :value="new Date().getFullYear() + 11">{{
              (new Date().getFullYear() + 11).toString().slice(2, 4)
            }}</option>
            <option :value="new Date().getFullYear() + 12">{{
              (new Date().getFullYear() + 12).toString().slice(2, 4)
            }}</option>
            <option :value="new Date().getFullYear() + 13">{{
              (new Date().getFullYear() + 13).toString().slice(2, 4)
            }}</option>
          </select>
          <span v-if="validateYear" class="msgError"
            >Este campo es obligatorio</span
          >
          <span v-if="year" class="newlatter-error-input">Año vencimiento</span>
        </div>
        <div class="payment-input-100 d-flex flex-wrap mb-0">
          <div class="payment-input-left position-relative">
            <input
              v-model="codCCV"
              class="payment-input w-100"
              type="number"
              maxlength="4"
              placeholder="Código CVV"
            />
            <span v-if="validateCodCCV" class="msgError"
              >Este campo es obligatorio</span
            >
            <span v-if="codCCV" class="newlatter-error-input">Código CVV</span>
          </div>
          <div
            class="col-12 col-md-auto payment-input-right popover-card-payment"
          >
            <b-link id="link-popover">
              <img class="pe-3" src="@/assets/icons/credit-card.svg" /> ¿Dónde
              encuentro el código CVV?</b-link
            >
            <b-popover
              placement="bottom"
              target="link-popover"
              triggers="hover"
            >
              <h5 class="font-weight-bold">Código de seguridad CVV</h5>
              <p class="fs-6">
                Los últimos tres (3) o cuatro (4) dígitos del número impreso al
                respaldo de tu tarjeta corresponden al código de seguridad (CVV)
                o CVV2. Si tienes una tarjeta de crédito American Express, el
                código lo encuentra en la parte delantera.
              </p>
            </b-popover>
          </div>
        </div>
        <div class="payment-input-100 position-relative">
          <select v-model="nCuotas" class="payment-input w-100">
            <option value="">Número de cuotas</option>
            <option value="1">1 cuota mensual</option>
            <option value="2">2 cuotas mensuales</option>
            <option value="3">3 cuotas mensuales</option>
            <option value="4">4 cuotas mensuales</option>
            <option value="5">5 cuotas mensuales</option>
            <option value="6">6 cuotas mensuales</option>
            <option value="7">7 cuotas mensuales</option>
            <option value="8">8 cuotas mensuales</option>
            <option value="9">9 cuotas mensuales</option>
            <option value="10">10 cuotas mensuales</option>
            <option value="11">11 cuotas mensuales</option>
            <option value="12">12 cuotas mensuales</option>
          </select>
          <span v-if="validateNCuotas" class="msgError"
            >Este campo es obligatorio</span
          >
          <span v-if="nCuotas" class="newlatter-error-input"
            >Número de cuotas</span
          >
        </div>
        <div class="payment-input-100 position-relative">
          <input
            v-model="nombre"
            class="payment-input w-100"
            type="cc-name"
            placeholder="Titular de la tarjeta"
          />
          <span v-if="validateNombre" class="msgError"
            >Este campo es obligatorio</span
          >
          <span v-if="nombre" class="newlatter-error-input"
            >Titular de la tarjeta</span
          >
        </div>
        <div class="position-relative payment-input-left">
          <select v-model="typeDoc" class="payment-select w-100">
            <option value="">Tipo de documento</option>
            <option value="CC">Cédula de ciudadanía</option>
            <option value="CE">Cédula de extranjería</option>
            <option value="PAS">Pasaporte</option>
            <option value="NIT">Nit</option>
          </select>
          <span v-if="validateTypeDoc" class="msgError"
            >Este campo es obligatorio</span
          >
          <span v-if="typeDoc" class="newlatter-error-input"
            >Tipo de documento</span
          >
        </div>
        <div class="position-relative payment-input-right">
          <input
            v-model="doc"
            class="payment-input w-100"
            type="number"
            placeholder="Documento"
          />
          <span v-if="validateDoc" class="msgError"
            >Este campo es obligatorio</span
          >
          <span v-if="doc" class="newlatter-error-input">Documento</span>
        </div>
        <div class="d-flex justify-content-center w-100">
          <button
            class="btn-primary btn-finalize-payment w-100"
            type=""
            @click="validatePaymentCreditCard(null)"
          >
            Finalizar compra
          </button>
        </div>
      </div>
      <div v-if="errorCreditCard" class="my-3 text-center">
        <span class="msgError fs-6" v-html="errorCreditCard"></span>
      </div>
    </div>
  </div>
</template>
<script>
import { BPopover, BLink } from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import { GA4AddPayment, GA4Purchase } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  components: { BPopover, BLink },
  data() {
    return {
      nombre: '',
      nCreditCard: '',
      typeCard: '',
      typeDoc: '',
      doc: '',
      month: '',
      year: '',
      codCCV: '',
      nCuotas: '',
      /////////////////////////////////////
      validateNombre: false,
      validateNCreditCard: false,
      validateTypeCard: false,
      validateMonth: false,
      validateYear: false,
      validateCodCCV: false,
      validateNCuotas: false,
      validateTypeDoc: false,
      validateDoc: false,
      /////////////////////////////////////
    }
  },
  computed: {
    ...mapState('cart', [
      'dataCart',
      'productsCart',
      'errorCreditCard',
      'dataSummaryPayment',
      'msgModalExcepcion406',
    ]),
    ...mapState(['esFE']),
    validateDNI: function() {
      if (this.typeDoc == 'CC') {
        const re1 = /([0-9]){7}/
        return (
          re1.test(this.doc) && this.doc.length >= 7 && this.doc.length <= 10
        )
      } else if (this.typeDoc == 'CE') {
        const re2 = /([0-9]){6}/
        return re2.test(this.doc) && this.doc.length >= 6
      } else if (this.typeDoc == 'PAS') {
        const re3 = /([0-9A-Za-z]){5}/
        return re3.test(this.doc) && this.doc.length >= 5
      } else if (this.typeDoc == 'NIT') {
        return this.doc.length == 9
      }
      return false
    },
    getDataAnalytics() {
      let arrayTemp = []
      this.productsCart.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          brand: product.marca,
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
    getDataProductsCRTO() {
      let arrayTemp = []
      this.productsCart.forEach(product => {
        arrayTemp.push({
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  watch: {
    dataCart() {
      if (this.dataCart.proveedor_pasarela == 'mercadopago') location.reload()
    },
  },
  mounted() {
    this.createForm()
  },
  methods: {
    ...mapActions('cart', ['paymentCreditcard']),
    async validatePaymentCreditCard(payload) {
      this.validateData()
      if (
        (this.nombre.length > 4 &&
          this.typeCard &&
          this.validateDNI &&
          this.month &&
          this.year &&
          this.codCCV.length >= 3 &&
          this.codCCV.length <= 4 &&
          this.nCuotas) ||
        payload
      ) {
        this.$store.state.loaderDerek = true
        sendFBQEvent(this, 'InitiateCheckout', {})
        await this.paymentCreditcard({
          paymentMethodID: this.typeCard,
          securityCode: this.codCCV,
          cardExpirationYear: this.year,
          cardholderName: this.nombre,
          docNumber: this.doc,
          cardNumber: this.nCreditCard,
          cardExpirationMonth: this.month,
          docType: this.typeDoc,
          installments: this.nCuotas,
          esFE: this.esFE,
          payloadMercadoPago: payload,
        })
          .then(() => {
            if (this.msgModalExcepcion406) {
              this.$bvModal.show('modal-error-406')
            } else {
              GA4AddPayment('Tarjeta de crédito / débito')
              GA4Purchase()
              sendFBQEvent(this, 'Purchase', {
                value: this.dataCart.carrito_total,
                currency: 'COP',
                contents: this.dataSummaryPayment.productos_purchase,
                content_ids: this.dataSummaryPayment.productos_purchase.map(
                  e => e.id,
                ),
                event_id: this.dataSummaryPayment.pedido.pedido
                  .pedido_reference_sale,
                content_type: 'product',
              })
              var deviceType = /iPad/.test(navigator.userAgent)
                ? 't'
                : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
                    navigator.userAgent,
                  )
                ? 'm'
                : 'd'
              window.criteo_q.push(
                { event: 'setAccount', account: 67539 },
                {
                  event: 'setEmail',
                  email: this.dataSummaryPayment.pedido.carrito.cliente_correo,
                },
                { event: 'setSiteType', type: deviceType },
                { event: 'setZipcode', zipcode: '##Zip Code##' },
                {
                  event: 'trackTransaction',
                  id: this.dataSummaryPayment.pedido.carrito.reference_sale,
                  item: this.getDataProductsCRTO,
                },
              )
              this.$emit('nextStep')
            }
            this.$store.state.loaderDerek = false
            this.$emit('nextStep', 1)
          })
          .catch(() => {
            this.$store.state.loaderDerek = false
          })
      }
    },
    validateData() {
      this.validateNombre = !(this.nombre.length > 4)
      this.validateNCreditCard = !this.typeCard
      this.validateTypeDoc = !this.typeDoc
      this.validateDoc = !this.validateDNI
      this.validateMonth = !this.month
      this.validateYear = !this.year
      this.validateCodCCV = !(
        this.codCCV.length >= 3 && this.codCCV.length <= 4
      )
      this.validateNCuotas = !this.nCuotas
    },
    validateCreditCard() {
      let data = new FormData()
      data.append('creditCardNumber', this.nCreditCard)
      this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/payu/credit_card_info',
        data: data,
      }).then(response => {
        this.typeCard = response.data ? response.data.scheme : ''
      })
    },
    createForm() {
      const mp = new window.MercadoPago(
        'APP_USR-df326da2-ce9a-4da2-bb1c-172c4d1c3d80',
      )
      const cardForm = mp.cardForm({
        amount: this.dataCart.carrito_total,
        iframe: true,
        form: {
          id: 'form-checkout',
          cardNumber: {
            id: 'form-checkout__cardNumber',
            placeholder: 'Numero de tarjeta',
          },
          expirationDate: {
            id: 'form-checkout__expirationDate',
            placeholder: 'MM/YY',
          },
          securityCode: {
            id: 'form-checkout__securityCode',
            placeholder: 'Código de seguridad',
          },
          cardholderName: {
            id: 'form-checkout__cardholderName',
            placeholder: 'Titular de la tarjeta',
          },
          issuer: {
            id: 'form-checkout__issuer',
            placeholder: 'Banco emisor',
          },
          installments: {
            id: 'form-checkout__installments',
            placeholder: 'Cuotas',
          },
          identificationType: {
            id: 'form-checkout__identificationType',
            placeholder: 'Tipo de documento',
          },
          identificationNumber: {
            id: 'form-checkout__identificationNumber',
            placeholder: 'Número del documento',
          },
          cardholderEmail: {
            id: 'form-checkout__cardholderEmail',
            placeholder: 'E-mail',
          },
        },
        callbacks: {
          onFormMounted: error => {
            if (error)
              return console.warn('Form Mounted handling error: ', error)
            console.log('Form mounted')
          },
          onSubmit: event => {
            event.preventDefault()
            const {
              paymentMethodId,
              issuerId,
              token,
            } = cardForm.getCardFormData()
            this.validatePaymentCreditCard({ paymentMethodId, issuerId, token })
          },
          onFetching: resource => {
            console.log('Fetching resource: ', resource)
          },
        },
      })
    },
  },
}
</script>
<style>
#form-checkout {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: auto;
  font-size: 15px;
}
#form-checkout label {
  width: 100%;
  text-align: left;
  padding-left: 0.1rem;
  margin-bottom: 0.2rem;
}
#form-checkout > .input-quac_tj,
#form-checkout .input-quac_tj {
  height: 18px;
  display: flex;
  background: #ffffff;
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
  border-radius: 2px;
  padding-right: 1rem;
  padding-left: 1rem;
  min-height: 50px;
  border-radius: 0;
  margin-bottom: 1rem;
  width: 100%;
  max-height: 50px;
}
#form-checkout .progress-bar {
  width: 100%;
  background-color: #e9f0fd;
}
</style>
