<template>
  <div class="card-store">
    <div class="container-card-store">
      <div class="container-img-hover">
        <router-link
          :to="{
            name: 'ProductDetail',
            params: {
              product: product.url.replace('product-', ''),
              vitrinaId: vitrinaId,
              vitrinaName: vitrinaName,
            },
            query: { color: product.color },
          }"
        >
          <img
            loading="lazy"
            :src="imagesProduct[0]"
            :alt="product.producto_titulo"
            class="img-product-store"
          />
          <img
            loading="lazy"
            :src="imagesProduct[1] ? imagesProduct[1] : imagesProduct[0]"
            :alt="product.producto_titulo"
            class="img-product-store-hover"
          />
        </router-link>
        <div style="position: absolute; top: 0; left: 0; z-index: 1">
          <span
            v-for="(tag, index) in product.tags"
            :key="index"
            :style="
              `background-color: ${tag.color_fondo}; color: ${tag.color_texto}`
            "
            class="store-product-tags"
            >{{ tag.tag }}</span
          >
        </div>
        <button-fav :data-product="product" class="store-fav" />
        <div v-if="product.exclusivo_online" class="container-exclisivo-online">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="me-1"
            width="11.321"
            height="10.815"
            viewBox="0 0 11.321 10.815"
          >
            <path
              id="Icon_feather-star"
              data-name="Icon feather-star"
              d="M8.16,3,9.755,6.23l3.566.521-2.58,2.513.609,3.55L8.16,11.138,4.971,12.815l.609-3.55L3,6.752,6.566,6.23Z"
              transform="translate(-2.5 -2.5)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1"
            />
          </svg>
          EXCLUSIVO ONLINE
        </div>
        <div
          v-if="product.tallas.length >= 1 && product.tallas[0].id != 'UN'"
          class="container-sizes-card-store"
        >
          <div
            v-if="product.exclusivo_online"
            class="container-exclisivo-online-size"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="me-1"
              width="11.321"
              height="10.815"
              viewBox="0 0 11.321 10.815"
            >
              <path
                id="Icon_feather-star"
                data-name="Icon feather-star"
                d="M8.16,3,9.755,6.23l3.566.521-2.58,2.513.609,3.55L8.16,11.138,4.971,12.815l.609-3.55L3,6.752,6.566,6.23Z"
                transform="translate(-2.5 -2.5)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </svg>
            EXCLUSIVO ONLINE
          </div>
          <router-link
            v-for="(item, index) in product.tallas"
            :key="index"
            :to="{
              name: 'ProductDetail',
              params: {
                product: product.url.replace('product-', ''),
                talla: item.id,
                vitrinaId: vitrinaId,
                vitrinaName: vitrinaName,
              },
              query: { color: product.color },
            }"
            class="size"
            :class="index == product.tallas.length - 1 ? 'me-0' : 'me-1'"
          >
            <span>{{ item.name }}</span>
          </router-link>
        </div>
      </div>
      <div class="content-info-product-store">
        <router-link
          :to="{
            name: 'ProductDetail',
            params: {
              product: product.url.replace('product-', ''),
              vitrinaId: vitrinaId,
              vitrinaName: vitrinaName,
            },
            query: { color: product.color },
          }"
          class="d-flex flex-wrap align-items-center w-100"
        >
          <div class="col-md-8 col-12 text-title">
            <p
              style="padding-right: 0; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;"
            >
              {{
                product.producto_titulo
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())
              }}
            </p>
          </div>
          <div
            v-if="product.dia_siniva == 1"
            class="col-md-4 col-12 text-price-store"
            style="font-size: 1.1rem;"
          >
            <del
              >${{
                new Intl.NumberFormat('de-DE').format(product.producto_precio)
              }}</del
            >
            <p
              class="text-end mt-1"
              style="line-height: .8; font-size: 1.1rem;"
            >
              <b
                >${{
                  new Intl.NumberFormat('de-DE').format(
                    product.producto_precio_siniva,
                  )
                }}</b
              >
              <br />
              <span style="font-size: 11px">*Precio sin IVA</span>
            </p>
          </div>
          <div v-else class="col-md-4 col-12 text-price-store">
            <p>
              ${{
                new Intl.NumberFormat('de-DE').format(product.producto_precio)
              }}
            </p>
            <del v-show="product.producto_precio_antes > 0"
              >${{
                new Intl.NumberFormat('de-DE').format(
                  product.producto_precio_antes,
                )
              }}</del
            >
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonFav from '@/components/Templates/ButtonFav.vue'
export default {
  components: { ButtonFav },
  props: {
    product: {
      type: Object,
      required: true,
    },
    vitrinaId: {
      type: String,
      required: true,
    },
    vitrinaName: {
      type: String,
      required: true,
    },
  },
  computed: {
    imagesProduct() {
      return this.product.imagenes
    },
  },
}
</script>
