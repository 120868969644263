var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"my-bag"}},[(_vm.loadingBag)?_c('div',{staticClass:"container-max-width"},[_c('skeleton-bag')],1):_c('div',{staticClass:"container-max-width"},[(_vm.productsCart && _vm.productsCart.length > 0)?_c('div',{staticClass:"container-my-bag"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('div',{staticClass:"col-12 px-3 products-bag"},[_c('div',{staticClass:"bag-title"},[_c('h3',[_vm._v("Tu bolsa")]),_c('p',[_vm._v(" "+_vm._s(_vm.productsCart.length)+" "+_vm._s(_vm.productsCart.length == 1 ? 'Producto' : 'Productos')+" ")])]),_vm._m(0),_c('div',{staticClass:"d-block d-md-none mb-4"},[_c('div',{staticClass:"data-bag-price justify-content-center"},[_c('p',{staticClass:"bold",staticStyle:{"font-size":"1.5rem"}},[_vm._v(" Total: $"+_vm._s(new Intl.NumberFormat('de-DE').format( _vm.dataCart.carrito_total, ))+" ")])]),_c('button',{staticClass:"btn-primary btn-finalice-bag",attrs:{"type":""},on:{"click":function($event){return _vm.$router.push({ name: 'Payment' })}}},[_vm._v(" Finalizar Compra ")])]),_c('div',{staticClass:"container-products-bag"},_vm._l((_vm.productGroup),function(product,index){return _c('products-bag',{key:index,attrs:{"product":product}})}),1)]),_vm._l((_vm.productStrategy),function(item,index){return _c('strategy-bag',{key:index,staticClass:"col-12 px-3",attrs:{"product-strategy":item}})}),_c('div',{staticClass:"mt-5 px-3 d-bock d-md-none",staticStyle:{"background-color":"#FAFAFA"}},[_c('detail-bag')],1),(_vm.productFeatured)?_c('div',{staticClass:"col-12 box-recommended"},[_c('div',{staticClass:"product-recommended",attrs:{"id":"carousel-vpt"}},[_c('h3',{staticClass:"col-7 col-xl-12",staticStyle:{"font-size":"24px"}},[_vm._v(" Completa tu compra / ")]),_c('div',[_c('div',{key:_vm.productFeatured
                    ? _vm.keyCarousel +
                      '-' +
                      _vm.productFeatured.length +
                      'Carousel...'
                    : _vm.keyCarousel + 'cargando...'},[_c('carousel',{staticClass:"col-12 pb-4 px-0 position-relative owl-carousel-container mb-4",attrs:{"dots":false,"lazy-load":true,"nav":false,"responsive":{
                    0: { items: 1, nav: false, stagePadding: 0 },
                    500: { items: 3, nav: false, stagePadding: 0 },
                    1200: { items: 4, nav: false, stagePadding: 0 },
                  }}},[_c('template',{slot:"prev"},[_c('span',{staticClass:"prev nav-button"},[_c('img',{staticStyle:{"transform":"rotate(180deg)"},attrs:{"src":require("@/assets/icons/arrow-btn-cupo.svg"),"alt":"left arrow carousel"}})])]),_vm._l((_vm.productFeatured),function(product,index){return _c('div',{key:index + product.producto_sku},[_c('card-product-hover',{attrs:{"product":product,"show-modal":true,"opt2":true,"vitrina-id":'vitrina-vpt',"vitrina-name":'Completa tu compra'}})],1)}),_c('template',{slot:"next"},[_c('span',{staticClass:"next nav-button"},[_c('img',{attrs:{"src":require("@/assets/icons/arrow-btn-cupo.svg"),"alt":"right arrow carousel"}})])])],2)],1)])])]):_vm._e()],2),_c('div',{staticClass:"col-4 d-none d-md-block",staticStyle:{"background-color":"#FAFAFA"}},[_c('detail-bag')],1)]):_c('div',[_c('div',{staticClass:"container-bag-empty"},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v("Aún no tienes productos en tu bolsa de compras :(")]),_c('p',[_vm._v(" Cuando agregues productos a tu bolsa los podrás ver en este espacio. ")]),_c('router-link',{attrs:{"to":{ name: 'Store' }}},[_c('button',{staticClass:"btn-primary px-4 fs-6"},[_vm._v("Quiero comprar")])])],1)])])]),_c('add-to-cart'),_c('modal-message',{key:_vm.msgErrorAddProduct,attrs:{"content":_vm.msgErrorAddProduct,"title":'Atención'}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert-stock-products-bag"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Ten en cuenta que los productos añadidos a la bolsa no se reservan. Finaliza tu compra para hacerlos tuyos. ")])])
}]

export { render, staticRenderFns }