<template>
  <div class="product-detail-container">
    <product-detail-skeleton v-if="!dataProduct" />
    <product-detail-desktop
      v-if="dataProduct && innerWidth >= 768"
      :key="currentColor + currentSize + 'Desktop'"
      class="d-none d-md-flex container-max-width"
      :data-product="dataProduct"
      :size="currentSize"
      :color="currentColor"
      @changeSize="changeSizeProduct($event)"
      @changeColor="changeColorProduct($event)"
      @selectProductOutfit="product = $event"
    />
    <product-detail-movil
      v-if="dataProduct && innerWidth < 768"
      class="pb-4"
      :data-product="dataProduct"
      :size="currentSize"
      :color="currentColor"
      @changeSize="changeSizeProduct($event)"
      @changeColor="changeColorProduct($event)"
      @selectProductOutfit="product = $event"
    />
    <product-detail-options
      v-if="dataProduct"
      id="product-detail-options"
      class="container-max-width"
      :data-product="dataProduct"
      :size="currentSize"
      :color="currentColor"
      @changeColor="changeColorProduct($event)"
      @changeSize="changeSizeProduct($event)"
    />
    <modal-product-detail v-if="product" :product="product" class="w-100" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { GA4ViewItem, GA4ViewPromotion } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'

import ProductDetailDesktop from '@/components/ProductDetail/ProductDetailDesktop.vue'
import ProductDetailSkeleton from '@/components/ProductDetail/ProductDetailSkeleton.vue'
import ProductDetailMovil from '@/components/ProductDetail/ProductDetailMovil.vue'
import ProductDetailOptions from '@/components/ProductDetail/ProductDetailOptions.vue'
import ModalProductDetail from '@/components/Templates/ModalProductDetail.vue'

export default {
  components: {
    ProductDetailDesktop,
    ProductDetailSkeleton,
    ProductDetailMovil,
    ProductDetailOptions,
    ModalProductDetail,
  },
  data() {
    return {
      currentSize: '',
      currentColor: '',
      product: null,
      viewOutfit: false,
      viewRelated: false,
    }
  },
  computed: {
    ...mapState('products', ['dataProduct']),
    ...mapState('user', ['dataUser']),
    ...mapState(['innerWidth']),
    refProduct() {
      return this.dataProduct?.referencias.find(
        e => e.color_id == this.currentColor,
      )
    },
  },
  watch: {
    async $route(to, from) {
      this.product = null
      this.$store.state.cart.msgErrorAddProduct = ''
      if (to.query.color) {
        this.currentColor = to.query.color
      }
      if (to.path != from.path) {
        let url = 'product-' + this.$route.params.product
        let producto_sku = this.$route.params.sku ? this.$route.params.sku : ''
        if (to.params.talla) {
          this.currentSize = to.params.talla
        } else {
          this.currentSize = ''
        }
        await this.getDataProduct({ producto_sku: producto_sku, url: url })
        this.getProductsRelated({
          sku: producto_sku
            ? producto_sku
            : this.$route.params.product.split('-')[0],
        })
        this.getOutfitProductDetail({
          sku: producto_sku
            ? producto_sku
            : this.$route.params.product.split('-')[0],
        })
      }
    },
    async dataProduct() {
      window.scrollTo(0, 0)
      if (this.$route.query.color) {
        let updatecolor = this.dataProduct?.referencias.find(
          ref => ref.color_id == this.currentColor && ref.habilitado,
        )
        if (updatecolor) {
          this.currentColor = this.$route.query.color
          if (updatecolor.tallas.length == 1) {
            this.currentSize = updatecolor.tallas[0].id
          }
          this.changeColorProduct(this.currentColor)
        } else {
          let updatecolorX = this.dataProduct?.referencias.find(
            ref => ref.habilitado,
          )
          if (updatecolorX) {
            this.currentColor = updatecolorX.color_id
            this.$store.state.products.dataProduct.color = this.currentColor
            if (updatecolorX.tallas.length == 1) {
              this.currentSize = updatecolorX.tallas[0].id
            }
            this.changeColorProduct(this.currentColor)
          } else if (this.dataProduct) {
            this.$store.state.products.dataProduct.color = this.dataProduct?.referencias[0].color_id
            this.currentColor = this.dataProduct?.referencias[0].color_id
            if (this.dataProduct?.referencias[0].tallas.length == 1) {
              this.currentSize = this.dataProduct?.referencias[0].tallas[0].id
            }
            this.changeColorProduct(this.currentColor)
          }
        }
      } else if (this.dataProduct && !this.currentColor) {
        if (this.dataProduct && this.dataProduct.referencias) {
          this.currentColor = this.dataProduct.referencias.find(e => {
            if (e.habilitado) {
              return e
            }
          })?.color_id
          if (!this.currentColor) {
            this.currentColor = this.dataProduct.referencias[0].color_id
          }
        }
        this.changeColorProduct(this.currentColor)
      } else if (this.dataProduct && !this.$route.query.color) {
        let updatecolor = this.dataProduct.referencias.find(
          ref => ref.color_id == this.currentColor && ref.habilitado,
        )
        if (updatecolor) {
          this.$store.state.products.dataProduct.color = this.currentColor
        } else {
          this.$store.state.products.dataProduct.color = this.dataProduct.referencias[0].color_id
          this.currentColor = this.dataProduct.referencias[0].color_id
          this.changeColorProduct(this.currentColor)
        }
      } else {
        this.currentColor = this.dataProduct.referencias[0].color_id
        this.changeColorProduct(this.currentColor)
      }
      if (!this.$route.query.color) {
        this.$router
          .replace({ query: { color: this.currentColor } })
          .catch(() => {})
      }
      document.title = this.dataProduct
        ? this.dataProduct.titulo
            .toLowerCase()
            .replace(/^\w/, d => d.toUpperCase()) +
          ' Ref. ' +
          this.dataProduct.producto_sku +
          ' | DEREK Tienda Online'
        : 'Cargando...'
      sendFBQEvent(this, 'ViewContent', {
        value: this.dataProduct.precio,
        currency: 'COP',
        product_id: this.dataProduct.producto_sku,
        content_type: 'product_group',
        content_ids: '[' + this.dataProduct.producto_sku + ']',
      })
      if (this.dataProduct) {
        GA4ViewItem(
          this.$route.params.vitrinaId ?? 'enlace',
          this.$route.params.vitrinaName ?? 'enlace',
        )
      }
      const misCookies = document.cookie.split(';')
      let email = misCookies.find(e => {
        return e.includes('email')
      })
      var deviceType = /iPad/.test(navigator.userAgent)
        ? 't'
        : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
            navigator.userAgent,
          )
        ? 'm'
        : 'd'
      window.criteo_q.push(
        { event: 'setAccount', account: 67539 },
        {
          event: 'setEmail',
          email: email
            ? email.replace('email=', '')
            : this.dataUser
            ? this.dataUser.correo
            : '',
        },
        { event: 'setSiteType', type: deviceType },
        { event: 'setZipcode', zipcode: '##Zip Code##' },
        { event: 'viewItem', item: this.dataProduct.producto_sku.toString() },
      )
    },
  },
  created() {
    this.$store.state.cart.msgErrorAddProduct = ''
    document.title = 'Producto | DEREK Tienda Online'
    let url = 'product-' + this.$route.params.product
    let producto_sku = this.$route.params.sku ? this.$route.params.sku : ''
    this.getDataProduct({ producto_sku: producto_sku, url: url })
    this.getOutfitProductDetail({
      sku: producto_sku
        ? producto_sku
        : this.$route.params.product.split('-')[0],
    })
    this.getProductsRelated({
      sku: producto_sku
        ? producto_sku
        : this.$route.params.product.split('-')[0],
    })
    if (this.$route.query.color) {
      this.currentColor = this.$route.query.color
    }
    if (this.$route.params.talla) {
      this.currentSize = this.$route.params.talla
    }
    window.addEventListener('scroll', this.listenScrollViewProduct)
  },
  methods: {
    ...mapActions('products', [
      'getDataProduct',
      'getOutfitProductDetail',
      'postShippingProduct',
      'getProductsRelated',
    ]),
    changeColorProduct(color) {
      this.currentColor = color
      let query = Object.assign({}, this.$route.query)
      query.color = color
      if (this.dataProduct && this.currentColor) {
        this.$store.state.products.dataProduct.color = this.currentColor
        let updatecolorV2 = this.dataProduct.referencias.find(
          ref => ref.color_id == this.currentColor,
        )
        if (
          this.currentColor &&
          updatecolorV2 &&
          updatecolorV2.tallas.length == 1
        ) {
          if (updatecolorV2.tallas[0].stock > 0) {
            this.currentSize = updatecolorV2.tallas[0].id
          }
        } else {
          if (!(this.$route.params.talla && this.currentSize)) {
            this.currentSize = ''
          }
        }
      }
      this.$router.replace({ query }).catch(() => {})
    },
    changeSizeProduct(size) {
      this.$store.state.cart.msgErrorAddProduct = ''
      this.currentSize = size
    },
    getDataAnalytics(productos) {
      let arrayTemp = []
      productos.forEach(product => {
        arrayTemp.push({
          name: product.id + '-' + product.name,
          brand: product.brand,
          SKU: product.id,
          price: product.price,
          quantity: 1,
        })
      })
      return arrayTemp
    },
    listenScrollViewProduct() {
      let observerList = [
        {
          name: 'outfit-product-detail',
          value: 'viewOutfit',
          data: 'refProduct.outfit',
        },
      ]
      observerList.forEach(item => {
        let outfitCard = document.getElementById(item.name)
        if (
          outfitCard &&
          eval(`!this.${item.value}`) &&
          outfitCard.getBoundingClientRect().top -
            (window.innerHeight - outfitCard.offsetHeight / 2) <=
            0
        ) {
          eval(`this.${item.value} = true`)
          let dataProducts = eval(`this.${item.data}`)
          if (Array.isArray(dataProducts.productos)) {
            let productsGA4 = []
            dataProducts.productos.forEach(product =>
              productsGA4.push({
                item_name: product.producto_titulo,
                item_id: product.producto_sku,
                price: parseInt(product.producto_precio),
                item_brand: product.marca,
                affiliation: 'Derek',
                item_category: product.categoria,
                item_category2: product.subcategoria,
                promotion_name: product.descuento ? product.descuento : '',
                discount: parseInt(product.producto_precio_antes)
                  ? parseInt(product.producto_precio_antes) -
                    parseInt(product.producto_precio)
                  : 0,
                item_variant: this.currentColor,
                quantity: 1,
                currency: 'COP',
              }),
            )
            GA4ViewPromotion({
              nameCreative: `outfit product detail ${dataProducts.nombre}`,
              slot: `detalle de producto outfit ${dataProducts.id}`,
              id: `outfit ${dataProducts.nombre} ${dataProducts.id}`,
              name: `${dataProducts.nombre}`,
              items: productsGA4,
            })
            console.log('Send GA4')
          }
        }
      })
    },
  },
}
</script>
